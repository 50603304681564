import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
} from "antd";
import InputStyled from "components/inputStyled";
import IssueServices from "config/services/IssuesServices";

const FormItem = Form.Item;
const { Option } = Select;

const AddIssueModal = ({ visible, closeModal, refetchData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([
    {
      id: Date.now(),
      question: "",
      flow_num: "",
      is_image: false,
    },
  ]);

  const [form] = Form.useForm();

  const handleFieldUpdate = (key, value, index) => {
    questions[index][key] = value;

    setQuestions([...questions]);
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      console.log(values);

      const { name, ...rest } = values;

      // const questionsList = [];

      // for (let key in rest) {
      //   questionsList.push({
      //     question: key.startsWith("question") && rest[key],
      //     flow_num: key.startsWith("flow_num") && rest[key],
      //     is_image: key.startsWith("is_image") && rest[key],
      //   });
      // }

      const payload = { name, questions: questions };

      console.log(payload);

      setIsLoading(true);

      const res = await IssueServices.createIssue(payload);

      console.log(res);

      if (res.message === "success") {
        Modal.success({
          title: "Incident added successfully",
          onOk: async () => {
            await refetchData();
            closeModal();
          },
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(error);
    }
  };

  const handleAddMoreQuestion = (event) => {
    event.preventDefault();

    setQuestions((prevQuestion) => [
      ...prevQuestion,
      {
        id: Date.now(),
        question: "",
        flow_num: "",
        is_image: false,
      },
    ]);
  };

  return (
    <Modal
      width={600}
      title={<strong>Add New Incident</strong>}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          loading={isLoading}
          key="create"
          type="primary"
          onClick={handleSubmit}
        >
          Ok
        </Button>,
      ]}
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <Col>
          <FormItem
            label="Type of Incident"
            name="name"
            rules={[
              {
                message: "Please enter incident type!",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Type of Incident" />
          </FormItem>
        </Col>

        <p>
          <strong>Add Questions</strong>
        </p>

        {questions.map((q, idx) => {
          return (
            <Card className="mb-2">
              <Col>
                <FormItem
                  label={`Question ${idx + 1}`}
                  name={`question_${idx + 1}`}
                  rules={[
                    {
                      message: "Please enter question!",
                      required: true,
                    },
                  ]}
                >
                  <InputStyled
                    onChange={(e) => {
                      handleFieldUpdate("question", e.target.value, idx);
                    }}
                    placeholder={`Question ${idx + 1}`}
                  />
                </FormItem>
              </Col>

              <Col>
                <FormItem
                  label={`Flow ${idx + 1}`}
                  name={`flow_num_${idx + 1}`}
                  rules={[
                    {
                      message: "Please enter flow number!",
                      required: true,
                      // type: "number",
                    },
                  ]}
                >
                  <InputStyled
                    type="number"
                    onChange={(e) => {
                      handleFieldUpdate("flow_num", e.target.value, idx);
                    }}
                    placeholder={`Flow Number ${idx + 1}`}
                  />
                </FormItem>
              </Col>

              <Col>
                <FormItem
                  label={`Has Image ${idx + 1}`}
                  name={`is_image_${idx + 1}`}
                >
                  <Checkbox
                    onChange={(e) => {
                      handleFieldUpdate("is_image", e.target.checked, idx);
                    }}
                  >
                    Has Image
                  </Checkbox>
                </FormItem>
              </Col>
            </Card>
          );
        })}

        <p style={{ textAlign: "right" }}>
          <Link to={null} onClick={handleAddMoreQuestion}>
            Add more question
          </Link>
        </p>
      </Form>
    </Modal>
  );
};

export default AddIssueModal;
