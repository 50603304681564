import Spinner from "components/spinner";
import React, { Fragment } from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  ${(props) => props.height && `min-height: ${props.height}px;`}

  width: 100%;
  background: #fff;
  margin-top: 1.5rem;
  padding: 3rem;
  // padding: 1.2rem 1.2rem 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .title {
      font-weight: bold;

      h2 {
        margin-bottom: 0;
      }
    }

    .extra {
    }
  }

  .card-body {
    .table-responsive .ant-table-content {
      display: block;
      width: 100%;
      overflow-x: auto;
    }

    .incident-table .ant-table-row:hover {
      cursor: pointer;
    }
  }
`;

const CardWrapper = ({ title, loading, extra, height, children, ...rest }) => {
  return (
    <StyledWrapper height={height} {...rest}>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="card-header">
            {title ? (
              <div className="title">
                <h2>{title}</h2>
              </div>
            ) : null}

            {extra ? <div className="extra">{extra}</div> : null}
          </div>

          <div className="card-body">{children}</div>
        </Fragment>
      )}
    </StyledWrapper>
  );
};

export default CardWrapper;
