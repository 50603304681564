import React, { createContext, useContext } from "react";
import useAsync from "hooks/useAsync";
import EscalatorServices from "config/services/EscalatorServices";

const EscalatorContext = createContext();

const EscalatorProvider = ({ children }) => {
  const { data, loading, refetchData } = useAsync(
    EscalatorServices.getEscalators
  );

  return (
    <EscalatorContext.Provider
      value={{
        data: data.data,
        loading,
        refetchData,
      }}
    >
      {children}
    </EscalatorContext.Provider>
  );
};

export function useEscalatorContext() {
  const context = useContext(EscalatorContext);

  if (context === undefined) {
    throw new Error(
      "useEscalatorContext should be used within an EscalatorProvider"
    );
  }

  const { data, loading, refetchData } = context;

  return { data, loading, refetchData };
}

export default EscalatorProvider;
