import React, { useState } from "react";
import {
  Button,
  Calendar,
  Card,
  Col,
  Empty,
  Row,
  Select,
  notification,
} from "antd";
import useToggle from "hooks/useToggle";
import { PlusOutlined } from "@ant-design/icons";
import AlertItem from "./alertItem";
import { FilterSelector, Legend, StyledCardWrapper } from "./alertStyles";
import AddCaseModal from "./addCaseModal";
import { useMessageContext } from "context/messages";
import MessageDetailModal from "../messageDetailModal";
import { onMessage, messaging } from "../../../firebase";

const { Option } = Select;

const Alert = () => {
  const [currMessage, setCurrMessage] = useState({});
  const {
    loading,
    data: messages,
    fetchFilteredPendingMessages,
    refetchData,
  } = useMessageContext();

  function onPanelChange(value, mode) {
    const selectedYear = value.format("YYYY-MM-DD");
    fetchFilteredPendingMessages(selectedYear);
  }

  onMessage(messaging, (payload) => {
    console.log("Foreground Message received. ", payload);

    notification.info({
      description: payload.notification.body,
      message: payload.notification.title,
    });

    refetchData();
  });

  const [isMessageDetailOpen, toggleMessageDetail] = useToggle();
  const [isAddCaseModalOpen, toggleAddCaseModal] = useToggle();

  const handleToggleMessageDetail = (value) => {
    setCurrMessage(value);
    toggleMessageDetail();
  };

  return (
    <section>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={18}>
          {loading ? (
            <Card loading={loading} />
          ) : (
            <StyledCardWrapper
              title="Incidents"
              extra={
                <Button
                  onClick={toggleAddCaseModal}
                  className="alertAdd"
                  type="primary"
                >
                  <PlusOutlined />
                  Add New Case
                </Button>
              }
            >
              {messages?.length > 0 ? (
                messages?.map((message, i) => (
                  <AlertItem
                    key={message.id}
                    message={message}
                    idx={i + 1}
                    handleToggleMessageDetail={handleToggleMessageDetail}
                  />
                ))
              ) : (
                <Empty />
              )}
            </StyledCardWrapper>
          )}
        </Col>

        <Col lg={6}>
          <Card style={{ minHeight: "100vh" }}>
            <Calendar fullscreen={false} onPanelChange={onPanelChange} />

            <Legend>
              <h3 className="title">My Legend</h3>

              <div className="legend-item">
                <span className="bg-color blue"></span>
                <span className="text">Assigned</span>
              </div>

              <div className="legend-item">
                <span className="bg-color yellow"></span>
                <span className="text">Pending</span>
              </div>

              <div className="legend-item">
                <span className="bg-color pink"></span>
                <span className="text">Escalated</span>
              </div>

              <div className="legend-item">
                <span className="bg-color green"></span>
                <span className="text">Completed</span>
              </div>
            </Legend>
          </Card>
        </Col>
      </Row>

      {isAddCaseModalOpen ? (
        <AddCaseModal
          visible={isAddCaseModalOpen}
          closeModal={toggleAddCaseModal}
          refetchData={refetchData}
        />
      ) : null}

      {isMessageDetailOpen ? (
        <MessageDetailModal
          currMessage={currMessage}
          visible={isMessageDetailOpen}
          closeModal={toggleMessageDetail}
        />
      ) : null}
    </section>
  );
};

export default Alert;
