import styled from "styled-components";

const OptionsMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const TableWrapper = styled.div`
  margin-top: 2rem;
`;

const CustomTableWrapper = styled.table`
  margin: 2rem 0;
  border: 1px solid;
  width: 100%;

  .underline {
    text-decoration: underline;
  }

  tr {
    border-bottom: 1px solid;

    th {
      padding: 0.5rem 1rem;
      margin-bottom: 1rem;

      :first-child {
        border-right: 1px solid;
      }
    }

    td {
      padding: 0.5rem 1rem;

      :first-child {
        width: 30%;
        border-right: 1px solid;
      }
    }
  }
`;

export { OptionsMenu, TableWrapper, CustomTableWrapper };
