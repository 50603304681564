import React from "react";
import { Table } from "antd";
import { Header } from ".";

const EmergencyEscalationReports = ({ emergencyEscalationReports }) => {
  const columns = [
    {
      title: "S/N",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "Local Government Area",
      dataIndex: "lga",
      key: "lga",
      align: "center",
    },
    {
      title: "Emergency Cases",
      dataIndex: "emergency_cases",
      key: "emergency_cases",
      align: "center",
    },
  ];

  return (
    <div style={{ marginTop: "2rem" }}>
      <Header>Total Emergency cases by L.G.A</Header>

      <Table
        className="table-responsive"
        columns={columns}
        dataSource={emergencyEscalationReports?.map((emerCase, idx) => ({
          rank: idx + 1,
          lga: emerCase.lga,
          emergency_cases: emerCase.emergency_cases,
        }))}
        pagination={false}
        bordered
      />
    </div>
  );
};

export default EmergencyEscalationReports;
