import React, { useEffect, useState } from "react";
import CardWrapper from "components/cardWrapper";
import IssueServices from "config/services/IssuesServices";
import useAsync from "hooks/useAsync";
import { useHistory, useParams } from "react-router-dom";
import { Button, Card, Checkbox, Col, Form, Input, Modal, Space } from "antd";
import InputStyled, { InputNumberStyled } from "components/inputStyled";
import {
  ArrowLeftOutlined,
  DeleteFilled,
  EditFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { Fragment } from "react/cjs/react.production.min";
import useToggle from "hooks/useToggle";
import AddNewQuestionModal from "./addNewQuestionModal";

const FormItem = Form.Item;

const IncidentDetails = () => {
  const [questions, setQuestions] = useState([
    // {
    //   id: Date.now(),
    //   question: "",
    //   flow_num: "",
    //   is_image: false,
    // },
  ]);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [isAddNewQuestionModalOpen, toggleAddNewQuestionModal] =
    useToggle(false);

  const params = useParams();
  const history = useHistory();
  const [form] = Form.useForm();

  const incidentId = params.id;

  const {
    loading,
    data: { data },
    refetchData: refetchIncidentData,
  } = useAsync(() => IssueServices.getSingleIssue(incidentId));

  useEffect(() => {
    if (data) {
      console.log(data);
      setQuestions(data?.question_list);
    }
  }, [data]);

  let defaultValues = {};

  data?.question_list?.forEach(({ question, is_image, flow_num }, idx) => {
    defaultValues[`question_${idx + 1}`] = question;
    defaultValues[`flow_num_${idx + 1}`] = flow_num;
    defaultValues[`is_image_${idx + 1}`] = is_image;
  });

  const handleFieldUpdate = (key, value, index) => {
    currentQuestion[index][key] = value;

    setCurrentQuestion({ ...currentQuestion });
  };

  const handleUpdateQuestions = async (id, index) => {
    try {
      setIsLoading(true);
      //   const values = await form.validateFields();
      console.log(currentQuestion);

      const res = await IssueServices.updateQuestion(
        id,
        currentQuestion[index]
      );

      Modal.success({
        title: "Question has been updated successfully",
      });

      console.log(res);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleDeleteQuestion = async (id) => {
    try {
      setIsLoading(true);
      console.log(id);
      const res = await IssueServices.deleteQuestion(id);

      console.log(res);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <section>
      <div className="mb-2">
        <Button onClick={() => history.goBack()} icon={<ArrowLeftOutlined />}>
          Back
        </Button>
      </div>

      <CardWrapper title={data?.name} loading={loading}>
        <div className="mb-2">
          <p>
            <strong>Number of Occurrences: </strong>
            {data?.case_count}
          </p>

          <p>
            <strong>Date Added: </strong>
            {new Date(data?.date_created).toLocaleString()}
          </p>
        </div>

        {questions?.length === 0 ? (
          <div>
            <p>No Questions Added</p>
          </div>
        ) : (
          <Fragment>
            {questions.map((question, idx) => (
              <Form
                layout="vertical"
                form={form}
                initialValues={defaultValues}
                // onFinish={handleUpdateQuestions}
              >
                <Card className="mb-2">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h3>Question {idx + 1}</h3>

                    {currentQuestion[idx]?.id !== question.id && (
                      <Space size="small">
                        <Button
                          color="primary"
                          onClick={() =>
                            setCurrentQuestion({
                              ...currentQuestion,
                              [idx]: question,
                            })
                          }
                          icon={<EditFilled />}
                        />
                        <Button
                          onClick={() => {
                            handleDeleteQuestion(question.id);
                          }}
                          icon={<DeleteFilled />}
                          loading={isLoading}
                          danger
                        />
                      </Space>
                    )}
                  </div>
                  <Col>
                    <FormItem
                      label={`Title`}
                      name={`question_${idx + 1}`}
                      rules={[
                        {
                          message: "Please enter question!",
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          handleFieldUpdate("question", e.target.value, idx);
                        }}
                        disabled={currentQuestion[idx]?.id !== question.id}
                        value={question.question}
                        placeholder="Question"
                      />
                    </FormItem>
                  </Col>

                  <Col>
                    <FormItem
                      label={`Flow`}
                      name={`flow_num_${idx + 1}`}
                      rules={[
                        {
                          message: "Please enter flow number!",
                          required: true,
                          type: "number",
                        },
                      ]}
                    >
                      <InputNumberStyled
                        onChange={(value) => {
                          handleFieldUpdate("flow_num", value, idx);
                        }}
                        value={question.flow_num}
                        placeholder={`Flow Number ${idx + 1}`}
                        disabled={currentQuestion[idx]?.id !== question.id}
                      />
                    </FormItem>
                  </Col>

                  <Col>
                    <FormItem
                      // label={`Has Image ${idx + 1}`}
                      name={`is_image_${idx + 1}`}
                    >
                      <Checkbox
                        checked={
                          question.is_image || currentQuestion[idx]?.is_image
                        }
                        onChange={(e) => {
                          handleFieldUpdate("is_image", e.target.checked, idx);
                        }}
                        disabled={currentQuestion[idx]?.id !== question.id}
                      >
                        Has Image
                      </Checkbox>
                    </FormItem>
                  </Col>

                  {currentQuestion[idx]?.id === question.id && (
                    <Space size="middle">
                      <Button
                        onClick={() => {
                          delete currentQuestion[idx];
                          setCurrentQuestion({ ...currentQuestion });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => handleUpdateQuestions(question.id, idx)}
                        loading={isLoading}
                      >
                        Save
                      </Button>
                    </Space>
                  )}
                </Card>
              </Form>
            ))}

            <div style={{ textAlign: "right" }}>
              <Button
                type="primary"
                onClick={toggleAddNewQuestionModal}
                icon={<PlusOutlined />}
              >
                Add new question
              </Button>
            </div>
          </Fragment>
        )}
      </CardWrapper>

      {isAddNewQuestionModalOpen ? (
        <AddNewQuestionModal
          visible={isAddNewQuestionModalOpen}
          closeModal={toggleAddNewQuestionModal}
          issueId={incidentId}
          refetchData={refetchIncidentData}
        />
      ) : null}
    </section>
  );
};

export default IncidentDetails;
