import React, { useRef, useCallback } from "react";
import { DownOutlined } from "@ant-design/icons";
import { useRouteMatch } from "react-router-dom";
import { Link } from "react-router-dom";
import { AvatarButton, NavItemsStyled } from "./layoutStyles";

import { ReactComponent as AvatarIcon } from "asset/svg/avatar.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import useToggle from "hooks/useToggle";
import { useAuthContext } from "context/AuthContext";
import { DropdownItemStyled } from "../adminLayout/layoutStyles";

const DropdownItem = ({
  isOpen,
  toggleDropdown,
  handleLogOut,
  closeDropdown,
}) => {
  const ref = useRef();
  const handler = useCallback(() => closeDropdown(), [closeDropdown]);

  const {
    state: { adminInfo },
  } = useAuthContext();

  useOnClickOutside(ref, handler);

  const { url } = useRouteMatch();

  return (
    <DropdownItemStyled ref={ref} ml={0}>
      <AvatarButton onClick={toggleDropdown}>
        {/* <img src={Avatar} alt="Avatar" /> */}

        <span className="css-nxs594">
          <AvatarIcon />
        </span>

        <span className="text">{`${adminInfo?.first_name} ${adminInfo?.last_name}`}</span>
        <DownOutlined />
      </AvatarButton>

      {isOpen && (
        <ul className="content">
          <Link to={url + "/d/profile"}>
            <li>Profile</li>
          </Link>

          <Link to={url + "/d/change-password"}>
            <li>Change Password</li>
          </Link>

          <li onClick={() => handleLogOut()}>
            <Link
              onClick={(e) => {
                e.preventDefault();

                handleLogOut();
              }}
            >
              Log Out
            </Link>
          </li>
        </ul>
      )}
    </DropdownItemStyled>
  );
};

const NavItems = () => {
  const { url } = useRouteMatch();
  const [isDropdownOpen, toggleDropdown, closeDropdown] = useToggle(false);
  const { dispatch } = useAuthContext();

  const handleLogOut = () => {
    dispatch({ type: "USER_LOGOUT" });
    localStorage.removeItem("adminInfo");
  };

  return (
    <NavItemsStyled>
      <Link className="Alerts" to={url + "/d/incidents"}>
        Incidents
      </Link>
      <Link className="Reports" to={url + "/d/reports"}>
        Reports
      </Link>
      {/* <Link className="Messages" to={url + "/d/messages"}>
        Messages
      </Link>
      <Link className="Settings" to={url + "/d/settings"}>
        Settings
      </Link> */}

      <DropdownItem
        isOpen={isDropdownOpen}
        toggleDropdown={toggleDropdown}
        closeDropdown={closeDropdown}
        handleLogOut={handleLogOut}
      />
    </NavItemsStyled>
  );
};

export default NavItems;
