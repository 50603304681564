import React from "react";
import CardWrapper from "components/cardWrapper";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ["NPF", "LASEMA", "Fire Service", "LASTMA"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const LineChart = () => {
  return (
    <CardWrapper title="Most Efficient Responders">
      <Doughnut
        data={data}
        options={{
          maintainAspectRatio: true,
          responsive: true,
        }}
      />
    </CardWrapper>
  );
};

export default LineChart;
