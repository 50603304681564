import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAu7ahIxhMGj9aLVJv3_etazHofi9nCjfw",
  authDomain: "quicksos-be16b.firebaseapp.com",
  projectId: "quicksos-be16b",
  storageBucket: "quicksos-be16b.appspot.com",
  messagingSenderId: "757586796375",
  appId: "1:757586796375:web:b45aaa85660fee90876812",
  measurementId: "G-BDYXGPPGEQ",
};

const firebase = initializeApp(firebaseConfig);

const messaging = getMessaging(firebase);

const getFirebaseToken = async (setFirebaseKey, addFirebaseDeviceToken) => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BFJMhmd7I752xjeH7tqLZzo7N-RzdjurD7NwWXEUVaTjvjZLPPlHZPNf-a-nG-pCTeGNwadpbe5wNu0PTuR6ol8",
    });

    // setFirebaseKey(currentToken);

    return currentToken;
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export { onMessage, messaging, getFirebaseToken };
