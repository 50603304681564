import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import InputStyled from "components/inputStyled";
import IssueServices from "config/services/IssuesServices";

const FormItem = Form.Item;
const { Option } = Select;

const EditIssueModal = ({ visible, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const payload = { ...values };

      setIsLoading(true);

      const res = await IssueServices.createIssue(payload);

      console.log(res);

      if (res.message === "success") {
        Modal.success({
          title: "Message added successfully",
          // onOk: async () => {
          //   await refetchSectors();
          //   closeModal();
          // },
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(error);
    }
  };

  return (
    <Modal
      width={600}
      title={<strong>Add New Case</strong>}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          loading={isLoading}
          key="create"
          type="primary"
          onClick={handleSubmit}
        >
          Ok
        </Button>,
      ]}
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <Col lg={24}>
          <FormItem
            label="Name of Incident"
            name="name"
            rules={[
              {
                message: "Please enter your name!",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Name of Reporter" />
          </FormItem>
        </Col>
      </Form>
    </Modal>
  );
};

export default EditIssueModal;
