import React from "react";
import { Button, Modal, Tag } from "antd";
import styled from "styled-components";
import { useEscalatorContext } from "context/escalators";
import { isValidURL } from "utils/helpers";

const StyledModal = styled(Modal)`
  font-size: 16px;

  .text-underline {
    text-decoration: underline;
  }

  .ant-modal-header {
    padding: 24px 32px;
  }

  .ant-modal-body {
    padding: 2rem;
    // background-color: #fff;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mb-min {
    margin-bottom: 10px;
  }

  .mb-1 {
    margin-bottom: 1rem;
  }

  .mb-2 {
    margin-bottom: 2rem;
  }

  .title {
    strong {
      margin-right: 0.4rem;
    }
  }

  .box {
    width: 300px;
    height: 300px;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const Flex = styled.span`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
`;

const ImgWrapper = styled.div`
  display: flex;

  .box {
    max-width: 300px;

    img {
      width: 100%;
      height: auto;
    }
  }
`;

const ViewReportModal = ({ visible, closeModal, currReport }) => {
  const { data: agencies } = useEscalatorContext();

  const selectedEscalators = currReport?.escalators?.map((escalatorId) => {
    const result = agencies.find((agency) => agency.id === escalatorId);

    return {
      ...result,
      id: result.id,
      name: result.name,
      acronym: result.acronym,
    };
  });

  const escalatedAgencies = currReport?.agency_detail
    ?.map((agency) => agency.acronym)
    .toLocaleString();

  const record = currReport.status;

  const getStatus = (status) => {
    if (status === "pending") return "yellow";
    if (status === "responded") return "orange";
    if (status === "arrived" || status === "assigned") return "blue";
    if (status === "complete") return "green";
    if (status === "escalated") return "red";
    return "blue";
  };

  return (
    <StyledModal
      title={<strong>Report Details</strong>}
      visible={visible}
      onCancel={closeModal}
      width={900}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button key="create" type="primary" onClick={closeModal}>
          Ok
        </Button>,
      ]}
      destroyOnClose
    >
      <p className="title">
        <strong>Name of Reporter: </strong>
        {currReport.name}
      </p>

      <p className="title">
        <strong>Emergency: </strong>
        {currReport.issue}
      </p>

      <p className="title">
        <strong>Location: </strong>
        {currReport.address}
      </p>

      <p>
        <strong>Phone Number: </strong>
        {currReport.phone}
      </p>

      <p>
        <strong>Landmark: </strong>
        {currReport.landmark}
      </p>

      <p>
        <strong>L.G.A: </strong>
        {currReport.local_gov}
      </p>

      <p className="title">
        <strong>Status: </strong>
        <Tag className="capitalize" color={getStatus(record.toLowerCase())}>
          {record}
        </Tag>
      </p>

      <p>
        <strong>Agencies: </strong>
        {escalatedAgencies}
      </p>
      {/* 
      <p className="title">
        <strong>Description: </strong>
        {currReport?.description || "--"}
      </p> */}

      {/* <p className="third">
        <p className="title">
          <strong>Pictures:</strong>
        </p>

        {currReport.image_url1 || currReport.image_url2 ? (
          <ImgWrapper>
            {currReport.image_url1 && (
              <div className="box">
                <img src={currReport.image_url1} alt={currReport.issue} />
              </div>
            )}

            {currReport.image_url2 && (
              <div className="box">
                <img src={currReport.image_url2} alt={currReport.issue} />
              </div>
            )}
          </ImgWrapper>
        ) : null}
      </p> */}

      <p className="title">
        <strong>Agent Note: </strong>
        {currReport?.agent_note || "--"}
      </p>

      {/* <p>
        <strong>Selected Escalators</strong>

        <Flex>
          {selectedEscalators?.map((d) => (
            <p key={d.id}>{d.acronym}</p>
          ))}
        </Flex>
      </p> */}
      <p>
        <strong>Date of Incidence: </strong>
        {new Date(currReport.date_created).toLocaleString() || "--"}
      </p>

      <p>
        <strong>Date of Escalation: </strong>
        {new Date(currReport.date_escalated).toLocaleString() || "--"}
      </p>

      <div>
        <h2 className="text-underline">Conversation</h2>

        {currReport.response_data.map((response) => (
          <div className="mb-2">
            <p className="mb-min">
              <strong>Question: </strong>
              {response.question}
            </p>

            <p>
              <strong>Answer: </strong>
              {isValidURL(response.answer) ? (
                <div className="box">
                  <img src={response.answer} alt={currReport.issue} />
                </div>
              ) : (
                response.answer
              )}
            </p>
          </div>
        ))}
      </div>
    </StyledModal>
  );
};

export default ViewReportModal;
