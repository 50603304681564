import React, { useState } from "react";
import { Modal, Form, Button } from "antd";
import InputStyled from "components/inputStyled";
import AdminServices from "config/services/AdminServices";
import { useUserContext } from "context/user";
import AgencyServices from "config/services/AgencyServices";
import useAsync from "hooks/useAsync";

const lgas = [
  "Agege",
  "Ajeromi-Ifelodun",
  "Alimosho",
  "Amuwo-Odofin",
  "Badagry",
  "Apapa",
  "Epe",
  "Eti Osa",
  "Ibeju-Lekki",
  "Ifako-Ijaiye",
  "Ikeja",
  "Ikorodu",
  "Kosofe",
  "Lagos Island",
  "Mushin",
  "Lagos Mainland",
  "Ojo",
  "Oshodi-Isolo",
  "Shomolu",
  "Surulere Lagos State",
];

const FormItem = Form.Item;

const AddAgentModal = ({ visible, closeModal, refetchAgencyData }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      setIsLoading(true);

      const res = await AgencyServices.createAgency(values);

      if (res.message === "success") {
        Modal.success({
          title: "Agency added successfully",
          onOk: () => closeModal(),
        });
      }
      await refetchAgencyData();

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(error);
    }
  };

  return (
    <Modal
      title={<strong>Add New Agency</strong>}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          loading={isLoading}
          key="create"
          type="primary"
          onClick={() => handleSubmit()}
        >
          Add Agency
        </Button>,
      ]}
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <FormItem
          label="Agency Name"
          name="name"
          rules={[
            {
              message: "Please enter agency name!",
              required: true,
            },
          ]}
        >
          <InputStyled placeholder="Agency Name" />
        </FormItem>

        <FormItem
          label="Acronym"
          name="acronym"
          rules={[
            {
              message: "Please enter an acronym for the agency",
              required: true,
            },
          ]}
        >
          <InputStyled placeholder="Acronym" />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default AddAgentModal;
