import CardWrapper from "components/cardWrapper";
import styled from "styled-components";

const FilterSelector = styled.div`
  .filter-selector {
    margin-left: 1rem;
    width: 100px;
  }
`;

const StyledCardWrapper = styled(CardWrapper)`
  margin-top: 0 !important;
`;

const Legend = styled.div`
  margin-top: 2rem;

  .title {
    margin-bottom: 1rem;
  }

  .legend-item {
    display: flex;
    margin-bottom: 1rem;

    .bg-color {
      width: 25px;
      height: 25px;
      border-radius: 50%;
    }

    .blue {
      background-color: #c2dbff;
    }

    .yellow {
      background-color: #ffe282;
    }

    .pink {
      background-color: #f6a19b;
    }

    .green {
      background-color: #a1ff75;
    }

    .text {
      margin-left: 0.8rem;
    }
  }
`;

export { FilterSelector, StyledCardWrapper, Legend };
