import { Modal, Form } from "antd";
import styled from "styled-components";

const StyledModal = styled(Modal)`
  .ant-modal-content {
    background-color: #f4f4f4;
  }

  .mr-3 {
    margin-right: 1rem;
  }

  .ant-modal-body {
    padding: 1rem;
    background-color: #fff;
  }

  .header {
    padding: 1.5rem 1rem;
    font-size: 24px;
    margin-bottom: 0;
  }

  footer {
    padding: 1.5rem 1rem;
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;

    .escalate-btn,
    .archive-btn {
      width: 150px;
    }
  }

  .message-content {
    background-color: #fff;

    .box {
      height: 400px;
      width: 400px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .first,
    .second,
    .third,
    .fourth {
      padding: 1rem;
      border-bottom: 3px solid #f4f4f4;

      .title {
        span {
          display: inline-block;
          width: 200px;
          strong {
            display: block;
          }
        }
      }
    }
  }
`;

const ImgWrapper = styled.div`
  display: flex;

  .box {
    max-width: 300px;

    img {
      width: 100%;
      height: auto;
    }
  }
`;

const StyledForm = styled(Form)`
  margin: 1rem;
  padding-bottom: 1rem;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
`;

export { Flex, StyledForm, ImgWrapper, StyledModal };
