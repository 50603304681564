import React from "react";
import { Row, Col } from "antd";
import { SummaryCard } from "./dashboardStyles";
import useAsync from "hooks/useAsync";
import { ReactComponent as ReportedCases } from "asset/svg/reported-cases.svg";
import { ReactComponent as CheckIcon } from "asset/svg/check.svg";
import { ReactComponent as VoidIcon } from "asset/svg/void.svg";
import { ReactComponent as ChartIcon } from "asset/svg/chart.svg";
import AdminServices from "config/services/AdminServices";

const SummaryCards = () => {
  const { data } = useAsync(AdminServices.getDashboardData);

  const summaryData = [
    {
      title: "Reported Cases",
      data: data?.reported_cases || 0,
      icon: <ReportedCases />,
    },
    {
      title: "Pending Cases",
      data: data?.pending || 0,
      icon: <VoidIcon />,
    },
    {
      title: "Escalated Cases",
      data: data?.escalated || 0,
      icon: <ChartIcon />,
    },
    {
      title: "Solved Cases",
      data: data?.completed || 0,
      icon: <CheckIcon />,
    },
  ];

  return (
    <Row gutter={[16, 24]}>
      {summaryData.map((summary) => (
        <Col xs={24} sm={24} md={12} lg={6}>
          <SummaryCard>
            <div className="content">
              <p className="title">{summary.title}</p>
              <span className="data">{summary.data}</span>
            </div>
          </SummaryCard>
        </Col>
      ))}
    </Row>
  );
};

export default SummaryCards;
