export const getEmergencyName = (category) => {
  return category ? category.split("_").join(" ") : "";
};

export function isValidURL(string) {
  var res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%.\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%\+.~#?&//=]*)/g
  );
  return res !== null;
}

export const lgas = [
  "Agege",
  "Ajeromi-Ifelodun",
  "Alimosho",
  "Amuwo-Odofin",
  "Badagry",
  "Apapa",
  "Epe",
  "Eti Osa",
  "Ibeju-Lekki",
  "Ifako-Ijaiye",
  "Ikeja",
  "Ikorodu",
  "Kosofe",
  "Lagos Island",
  "Mushin",
  "Lagos Mainland",
  "Ojo",
  "Oshodi-Isolo",
  "Shomolu",
  "Surulere Lagos State",
];
