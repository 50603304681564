import requests from "./httpService";

const AdminServices = {
  // ========= AUTH ==========
  login(body) {
    return requests.post(`/account/users/auth/`, body);
  },
  addAdmin(body) {
    return requests.post(`/account/users/add/`, body);
  },
  changeFirebaseKey(body) {
    return requests.post(`/account/users/change_firebase_key`, body);
  },

  // ========= DASHBOARD ==========
  getDashboardData() {
    return requests.get(`/dashboard/`);
  },
  getMonthlyReports(body) {
    if (body) {
      return requests.post(`/dashboard/monthly-report/`, body);
    } else {
      return requests.get(`/dashboard/monthly-report`);
    }
  },
  getIndividualMonthlyReport(body) {
    return requests.post(`/dashboard/monthly-report`, body);
  },

  // ========= AGENTS ==========
  getAgents() {
    return requests.get(`/account/users/agents/`);
  },

  // ========= ESCALATORS ==========
  getEscalators() {
    return requests.get(`/account/users/escalators/`);
  },

  // ========= FIRST RESPONDER ==========
  getFirstResponders() {
    return requests.get(`/account/users/first_responders/`);
  },

  // ========= FORGOT PASSWORD ==========
  forgotPassword(body) {
    return requests.post("/account/users/forget_password/", body);
  },
  forgotPasswordConfirm(body) {
    return requests.post("/account/users/forget_password/confirm/", body);
  },
  validateToken(body) {
    return requests.post(`/account/users/forget_password/validate_token`, body);
  },
  resetPassword(body) {
    return requests.post("/account/users/reset_password/", body);
  },

  // ========= PROFILE ==========
  myProfile() {
    return requests.get(`/account/users/profile/`);
  },
  updateProfile(body) {
    return requests.put(`/account/users/profile/`, body);
  },
  deleteProfile() {
    return requests.delete("/account/users/profile");
  },

  // ========= USERS ==========
  editUser(id, body) {
    return requests.put(`/account/users/${id}`, body);
  },
  adminResetUserPassword(body) {
    return requests.post(`/account/users/admin-reset-user-password/`, body);
  },
  deleteUser(id) {
    return requests.delete(`/account/users/${id}`);
  },
};

export default AdminServices;
