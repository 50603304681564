import React from "react";

const NotFound = () => {
  return (
    <section>
      <h1>Page Not Found</h1>
    </section>
  );
};

export default NotFound;
