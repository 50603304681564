import React, { useState } from "react";
import { Button, Space, Tag } from "antd";
import CardWrapper from "components/cardWrapper";
import SearchBar from "components/searchbar";
import { MessageItem, OptionsMenu, TableWrapper } from "./messageStyles";

const Messages = () => {
  const [currReport, setCurrReport] = useState();

  return (
    <section>
      <CardWrapper title="Messages">
        <TableWrapper>
          <OptionsMenu>
            <Space>
              <Button type="primary">Copy</Button>
              <Button type="primary">CSV</Button>
              <Button type="primary">Excel</Button>
              <Button type="primary">PDF</Button>
              <Button type="primary">Print</Button>
            </Space>

            <SearchBar />
          </OptionsMenu>
        </TableWrapper>

        {new Array(12).fill("").map(() => (
          <MessageItem>
            <div>
              <p className="msg-header">
                <strong>Treat as urgent</strong>
                <span>16-11-2021, 12:34pm</span>
              </p>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
                amet, consectetur adipiscing elit .........
              </p>
            </div>

            <Button type="link">View</Button>
          </MessageItem>
        ))}
      </CardWrapper>
    </section>
  );
};

export default Messages;
