import React, { createContext, useContext } from "react";
import useAsync from "hooks/useAsync";
import IssuesServices from "config/services/IssuesServices";

const IssuesContext = createContext();

const IssuesProvider = ({ children }) => {
  const { data, loading, refetchData } = useAsync(IssuesServices.getIssues);

  return (
    <IssuesContext.Provider
      value={{
        data: data.data,
        loading,
        refetchData,
      }}
    >
      {children}
    </IssuesContext.Provider>
  );
};

export function useIssuesContext() {
  const context = useContext(IssuesContext);

  if (context === undefined) {
    throw new Error("useIssuesContext should be used within an IssuesProvider");
  }

  const { data, loading, refetchData } = context;

  return { data, loading, refetchData };
}

export default IssuesProvider;
