import React, { useState, useEffect } from "react";
import { Table, Button, Tag, message, Pagination } from "antd";
import CardWrapper from "components/cardWrapper";
import SearchBar from "components/searchbar";
import { TableWrapper } from "./reportStyles";
import { OptionsMenu } from "./reportStyles";
import useToggle from "hooks/useToggle";
import ViewReportModal from "./viewReportModal";
import MessageServices from "config/services/MessageServices";

const Reports = () => {
  const [current, setCurrent] = useState(1);
  const [currReport, setCurrReport] = useState();
  const [dateString, setDateString] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);

  const [isReportModalOpen, toggleReportModal] = useToggle();

  useEffect(() => {
    const fetchNextData = async () => {
      try {
        setLoading(true);
        const data = await MessageServices.getAllMessages(current, pageSize);

        setTotal(data?.count);
        setResults(data?.results);
        setFilteredMessages(
          data?.results?.map((message, idx) => ({
            ...message,
            rank: idx + 1,
            reporter_name: message.name,
            address: message.address,
            incidence: message.issue,
            status: message.status,
            date: new Date(message.date_created).toLocaleDateString(),
          }))
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchNextData();
  }, [current, pageSize]);

  const getStatus = (status) => {
    if (status === "pending") return "yellow";
    if (status === "responded") return "orange";
    if (status === "arrived" || status === "assigned") return "blue";
    if (status === "complete") return "green";
    if (status === "escalated") return "red";
    return "blue";
  };

  const columns = [
    {
      title: "S/N",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "Name of Reporter",
      dataIndex: "reporter_name",
      key: "reporter_name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Incidence",
      dataIndex: "incidence",
      key: "incidence",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",

      render: (record, key) => {
        return (
          <Tag className="capitalize" color={getStatus(record.toLowerCase())}>
            {record}
          </Tag>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (record, key) => {
        return (
          <Button
            className="ant-btn-link"
            onClick={() => {
              setCurrReport(record);
              toggleReportModal();
            }}
            type="link"
          >
            view
          </Button>
        );
      },
    },
  ];

  const onChange = (pageNumber) => {
    setCurrent(pageNumber);
  };

  const handleSearch = (value) => {
    const originalData = [
      ...results?.map((message, idx) => ({
        ...message,
        rank: idx + 1,
        key: idx + 1,
        reporter_name: message.name,
        address: message.address,
        incidence: message.issue,
        status: message.status,
        date: new Date(message.date_created).toLocaleDateString(),
      })),
    ];

    if (!value) setFilteredMessages(originalData);

    const result = originalData.filter((d) => {
      return Object.keys(d).some((key) =>
        String(d[key]).toLowerCase().includes(value.toLowerCase())
      );
    });

    if (result.length === 0) {
      message.warning("No Data Found!", 5);
    }

    setFilteredMessages(result);
  };

  return (
    <section>
      <CardWrapper title={`All Incidents (${filteredMessages.length})`}>
        <TableWrapper>
          <OptionsMenu>
            {/* <Space>
              <Button type="primary">Copy</Button>
              <Button type="primary">CSV</Button>
              <Button type="primary">Excel</Button>
              <Button type="primary">PDF</Button>
              <Button type="primary">Print</Button>
            </Space> */}

            <SearchBar handleSearch={handleSearch} />
          </OptionsMenu>

          <Table
            loading={loading}
            className="table-responsive"
            columns={columns}
            dataSource={filteredMessages}
            pagination={false}
          />
          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {loading ? null : (
              <Pagination
                current={current}
                pageSize={pageSize}
                total={total}
                onChange={onChange}
              />
            )}
          </div>
        </TableWrapper>
      </CardWrapper>

      {isReportModalOpen ? (
        <ViewReportModal
          visible={isReportModalOpen}
          closeModal={toggleReportModal}
          currReport={currReport}
        />
      ) : null}
    </section>
  );
};

export default Reports;
