import React, { Fragment } from "react";
import { Button, Modal, Tag } from "antd";
import styled from "styled-components";
import { useEscalatorContext } from "context/escalators";

const StyledModal = styled(Modal)`
  .title {
    strong {
      margin-right: 0.4rem;
    }
  }
`;

const Flex = styled.span`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
`;

const ImgWrapper = styled.div`
  display: flex;

  .box {
    max-width: 300px;

    img {
      width: 100%;
      height: auto;
    }
  }
`;

const ViewReportModal = ({ visible, closeModal, currReport }) => {
  const { data: agencies } = useEscalatorContext();

  const selectedEscalators = currReport?.escalators?.map((escalatorId) => {
    const result = agencies.find((agency) => agency.id === escalatorId);

    return {
      ...result,
      id: result.id,
      name: result.name,
      acronym: result.acronym,
    };
  });

  const record = currReport.status;

  const getStatus = (status) => {
    if (status === "pending") return "yellow";
    if (status === "responded") return "orange";
    if (status === "arrived") return "blue";
    if (status === "complete") return "green";
    return "blue";
  };

  return (
    <StyledModal
      title={<strong>Report Details</strong>}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button key="create" onClick={closeModal} type="primary">
          Ok
        </Button>,
      ]}
      destroyOnClose
    >
      <p className="title">
        <strong>Name of Reporter: </strong>
        {currReport.name}
      </p>

      <p className="title">
        <strong>Emergency: </strong>
        {currReport.issue}
      </p>

      <p className="title">
        <strong>Location: </strong>
        {currReport.address}
      </p>

      <p>
        <strong>Phone Number: </strong>
        {currReport.phone}
      </p>

      <p>
        <strong>Landmark: </strong>
        {currReport.landmark}
      </p>

      <p>
        <strong>L.G.A: </strong>
        {currReport.local_gov}
      </p>

      <p className="title">
        <strong>Status: </strong>
        <Tag className="capitalize" color={getStatus(record.toLowerCase())}>
          {record}
        </Tag>
      </p>

      {/* <p className="title">
        <strong>Description: </strong>
        {currReport?.description || "--"}
      </p>

      <p className="third">
        <p className="title">
          <strong>Pictures:</strong>
        </p>

        {currReport.image_url1 || currReport.image_url2 ? (
          <ImgWrapper>
            {currReport.image_url1 && (
              <div className="box">
                <img src={currReport.image_url1} alt={currReport.issue} />
              </div>
            )}

            {currReport.image_url2 && (
              <div className="box">
                <img src={currReport.image_url2} alt={currReport.issue} />
              </div>
            )}
          </ImgWrapper>
        ) : (
          "--"
        )}
      </p> */}

      <p className="title">
        <strong>Agent Note: </strong>
        {currReport?.case_detail?.agent_note || "--"}
      </p>

      <p className="title">
        <strong>Escalator Note: </strong>
        {currReport?.escalator_note || "--"}
      </p>

      {selectedEscalators?.length > 0 ? (
        <p>
          <strong>Selected Escalators</strong>

          <Flex>
            {selectedEscalators?.map((d) => (
              <p key={d.id}>{d.acronym}</p>
            ))}
          </Flex>
        </p>
      ) : null}

      {currReport?.report_detail?.length > 0 ? (
        <div>
          <h2>
            <strong>Report</strong>
          </h2>

          {currReport.report_detail.map((d) => (
            <Fragment>
              <p>
                <strong>Situation Report:</strong>

                {d.situation_report}
              </p>

              <p>
                <strong>Picture: </strong>
              </p>

              {(d.image_url1 || d.image_url2) && (
                <div style={{ minHeight: "300px", maxWidth: "300px" }}>
                  <img
                    src={d.image_url1 || d.image_url2}
                    alt="Incidence"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              )}
            </Fragment>
          ))}
        </div>
      ) : null}
    </StyledModal>
  );
};

export default ViewReportModal;
