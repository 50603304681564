import React, { useState } from "react";
import { Modal, Button } from "antd";
import { useUserContext } from "context/user";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AdminServices from "config/services/AdminServices";
import useToggle from "hooks/useToggle";
import EditFirstResponderModal from "./editFirstResponderModal";

const ViewFirstResponderModal = ({
  visible,
  closeModal,
  currFirstResponder,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { refetchFirstRespondersData } = useUserContext();

  const [isEditFirstResponderModalOpen, toggleEditFirstResponderModal] =
    useToggle();

  const resetUserPassword = async () => {
    try {
      setIsLoading(true);
      const res = await AdminServices.adminResetUserPassword({
        username: currFirstResponder.username,
      });

      if (res.message === "success") {
        Modal.success({
          title: "User password reset successful!",
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleResetFirstResponderModal = () => {
    Modal.confirm({
      title: "Are You Sure?",
      content: "This action is irreversible.",
      onOk: async () => {
        await resetUserPassword();
      },
      onCancel: () => {},
    });
  };

  const handleDeleteFirstResponderModal = () => {
    Modal.confirm({
      title: "Are You Sure?",
      content: "This action is irreversible.",
      onOK: async () => {
        await deleteFirstResponder(currFirstResponder.id);
      },
      onCancel: () => {},
    });
  };

  const deleteFirstResponder = async (id) => {
    try {
      await AdminServices.deleteUser(id);

      Modal.success({
        title: "FirstResponder deleted successfully!",
      });

      refetchFirstRespondersData();
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        title={<strong>FirstResponder Details</strong>}
        visible={visible}
        onCancel={closeModal}
        footer={[
          <Button
            key="reset-password"
            loading={isLoading}
            onClick={handleResetFirstResponderModal}
            danger
          >
            Reset Password
          </Button>,
          <Button
            key="edit"
            icon={<EditOutlined />}
            onClick={toggleEditFirstResponderModal}
          >
            Edit
          </Button>,
          <Button
            key="delete"
            icon={<DeleteOutlined />}
            onClick={handleDeleteFirstResponderModal}
            danger
          >
            Delete
          </Button>,
        ]}
        destroyOnClose
      >
        <div>
          <p>
            <strong>Name: </strong>
            {`${currFirstResponder.first_name} ${currFirstResponder.last_name}`}
          </p>

          <p>
            <strong>Username: </strong>
            {currFirstResponder.username}
          </p>

          <p>
            <strong>Email: </strong>
            {currFirstResponder.email}
          </p>

          <p>
            <strong>Phone: </strong>
            {currFirstResponder.phone}
          </p>

          <p>
            <strong>Address: </strong>
            {currFirstResponder.address}
          </p>

          <p>
            <strong>Agency: </strong>
            {currFirstResponder.agency}
          </p>

          <p>
            <strong>Gender: </strong>
            {currFirstResponder.gender}
          </p>
        </div>
      </Modal>

      {isEditFirstResponderModalOpen ? (
        <EditFirstResponderModal
          currFirstResponder={currFirstResponder}
          visible={isEditFirstResponderModalOpen}
          closeModal={toggleEditFirstResponderModal}
          refetchFirstRespondersData={refetchFirstRespondersData}
        />
      ) : null}
    </>
  );
};

export default ViewFirstResponderModal;
