import { Table } from "antd";
import React from "react";

const AgencyCasesTable = ({ agencyCases }) => {
  const columns = [
    {
      title: "S/N",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "AGENCY",
      dataIndex: "agency",
      key: "agency",
    },
    {
      title: "RESOLVED",
      dataIndex: "resolved",
      key: "resolved",
      align: "center",
    },
    {
      title: "UNRESOLVED",
      dataIndex: "unresolved",
      key: "unresolved",
      align: "center",
    },
    {
      title: "TOTAL",
      dataIndex: "total",
      key: "total",
      align: "center",
    },
  ];

  return (
    <Table
      className="table-responsive"
      columns={columns}
      dataSource={agencyCases?.map((agencyCase, idx) => ({
        // ...agencyCase,
        rank: idx + 1,
        agency: agencyCase.agency,
        resolved: agencyCase.data.resolved,
        unresolved: agencyCase.data.unresolved,
        total: agencyCase.data.total,
      }))}
      pagination={false}
      bordered
    />
  );
};

export default AgencyCasesTable;
