import styled from "styled-components";

const DummyContent = styled.div`
  height: 900px;
`;

const SummaryWrapper = styled.div`
  display: flex;
`;

const RowStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const SummaryCard = styled.div`
  background: #fff;
  padding: 1rem 1.5rem;
  border-radius: 6px;
  display: flex;

  .icon-wrapper {
    svg {
      margin-top: 8px;
      width: 24px;
      height: 24px;
    }
  }

  .content {
    /* margin-left: 1rem; */

    .title {
      font-size: 16px;
    }

    .data {
      font-size: 20px;
      font-weight: 600;
    }
  }
`;

export { DummyContent, SummaryWrapper, RowStyled, SummaryCard };
