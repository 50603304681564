import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";

const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
const Spinner = () => {
  return (
    <StyledPageContainer>
      <Spin indicator={antIcon} />
    </StyledPageContainer>
  );
};

const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default Spinner;
