import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, message, Modal } from "antd";
import { AuthSection, FormWrapper } from "../login/loginStyles";
import InputStyled from "components/inputStyled";
import AdminServices from "config/services/AdminServices";

const FormItem = Form.Item;

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      console.log(values);

      const res = await AdminServices.forgotPassword(values);

      if (res.status === "OK") {
        Modal.success({
          title: "Password reset mail sent successfully",
        });

        form.resetFields();
      }

      setLoading(false);
    } catch (error) {
      const errResponse = error.response;

      const errMsg = errResponse
        ? errResponse.data.email
        : "Sorry, an error occured!";

      message.error(errMsg);

      setLoading(false);
    }
  };

  return (
    <AuthSection>
      <div className="bg-wrapper"></div>

      <FormWrapper>
        <div className="max-width-wrapper">
          <div className="content">
            <h2 className="title">Forgot Password</h2>
            <p>Enter email to receive reset password link.</p>

            <Form
              layout="vertical"
              name="forgot-password-form"
              form={form}
              onFinish={handleSubmit}
            >
              <FormItem
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not a valid E-mail!",
                  },
                  {
                    message: "Please input your Email!",
                    required: true,
                  },
                ]}
              >
                <InputStyled placeholder="Email Address" />
              </FormItem>

              <div className="btn-submit-wrapper">
                <Button
                  loading={loading}
                  size="large"
                  type="primary"
                  htmlType="submit"
                >
                  Send
                </Button>
                <p>
                  Don’t have an account?
                  <Link to="/create-account">Sign Up</Link>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </FormWrapper>
    </AuthSection>
  );
};

export default ForgotPassword;
