import axios from "axios";
import { message as MessageInfo } from "antd";
import handleErrors from "./errorHandler";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 500000,
});

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {
  // Do something before request is sent
  let adminInfo;
  if (localStorage.getItem("adminInfo")) {
    adminInfo = JSON.parse(localStorage.getItem("adminInfo"));
  }

  return {
    ...config,
    headers: {
      authorization: adminInfo ? `Bearer ${adminInfo.access}` : null,
    },
  };
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    console.log(error.response);

    // Do something with response error
    const errorResponse = error.response;
    const originalConfig = error?.config;

    if (
      errorResponse.config.url === "/user/add_delivery_person/" ||
      errorResponse.config.url === "/user/add_admin/"
    ) {
      const errMsg = errorResponse.data.error.phone[0];

      MessageInfo.error(errMsg);

      return Promise.reject(error);
    }

    if (errorResponse.config.url === "/user/add_admin/") {
      const errMsg = errorResponse.data.error.email[0];

      MessageInfo.error(errMsg);

      return Promise.reject(error);
    }

    // ======= FORGOT PASSWORD AND RESET PASSWORD =======
    if (
      errorResponse.config.url === "/account/users/forget_password/confirm/" ||
      errorResponse.config.url === "/account/users/forget_password/"
    ) {
      return Promise.reject(error);
    }
    // ======= FORGOT PASSWORD AND RESET PASSWORD =======

    if (
      errorResponse.status === 401 &&
      (errorResponse.data.detail === "Token is blacklisted" ||
        errorResponse.data.detail === "Token is invalid or expired" ||
        errorResponse.data.detail === "User not found" ||
        errorResponse.data.code === "user_not_found")
    ) {
      localStorage.removeItem("adminInfo");

      window.location.href = "/login";

      console.log("Session has expired. Please login again.");

      MessageInfo.error("Session has expired. Please login again.");
    }

    if (
      errorResponse.status === 401 &&
      (errorResponse.data.detail === "User not found" ||
        errorResponse.data.code === "user_not_found")
    ) {
      localStorage.removeItem("adminInfo");

      window.location.href = "/login";

      console.log("User not found. Please login again.");

      MessageInfo.error("User not found. Please login again.");
    }

    const isNotLoginRoute = window.location.pathname !== "/login";

    if (
      isNotLoginRoute &&
      errorResponse.status === 401 &&
      errorResponse.data.detail !== "Token is blacklisted" &&
      !originalConfig._retry
    ) {
      console.log({ path: window.location.pathname, isNotLoginRoute });

      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalConfig.headers["authorization"] = "Bearer " + token;
            return axiosInstance(originalConfig);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalConfig._retry = true;
      isRefreshing = true;

      let adminInfo;
      if (localStorage.getItem("adminInfo")) {
        adminInfo = JSON.parse(localStorage.getItem("adminInfo"));
      }

      const refreshToken = adminInfo?.refresh;

      if (!refreshToken) {
        window.location.href = "/login";
        return;
      }

      return new Promise(function (resolve, reject) {
        axiosInstance
          .post("/account/users/auth/refresh/", { refresh: refreshToken })
          .then((res) => {
            const { access, refresh } = res.data;

            localStorage.setItem(
              "adminInfo",
              JSON.stringify({ ...adminInfo, access, refresh })
            );

            originalConfig.headers["authorization"] = `Bearer ${access}`;

            processQueue(null, access);
            resolve(axiosInstance(originalConfig));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    // ============== CENTRAL ERROR HANDLER ==============
    handleErrors(errorResponse);
    // ============== CENTRAL ERROR HANDLER ==============

    return Promise.reject(error);
  }
);

const responseBody = (response) => response.data;

const requests = {
  get: (url, body, headers) =>
    axiosInstance.get(url, body, headers).then(responseBody),

  post: (url, body) => axiosInstance.post(url, body).then(responseBody),

  put: (url, body, headers) =>
    axiosInstance.put(url, body, headers).then(responseBody),

  patch: (url, body) => axiosInstance.patch(url, body).then(responseBody),

  delete: (url) => axiosInstance.delete(url).then(responseBody),
};

export default requests;
