import React, { useEffect, useState } from "react";
import { Button, Table, Tag } from "antd";
import CardWrapper from "components/cardWrapper";
import useAsync from "hooks/useAsync";
import MessageServices from "config/services/MessageServices";
import ViewReportModal from "../reports/reportModal";
import useToggle from "hooks/useToggle";
import { getEmergencyName } from "utils/helpers";

const ReportedCases = () => {
  const [current, setCurrent] = useState(1);
  const [currReport, setCurrReport] = useState();
  const [dateString, setDateString] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);

  useEffect(() => {
    const fetchNextData = async () => {
      try {
        setLoading(true);
        const data = await MessageServices.getAllMessages(current, pageSize);

        setTotal(data?.count);
        setResults(data?.results);
        setFilteredMessages(
          data?.results?.map((message, idx) => ({
            ...message,
            rank: idx + 1,
            reporter_name: message.name,
            address: message.address,
            incidence: message.issue,
            status: message.status,
            date: new Date(message.date_created).toLocaleDateString(),
          }))
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchNextData();
  }, [current, pageSize]);

  const [isReportModalOpen, toggleReportModal] = useToggle();

  const columns = [
    {
      title: "S/N",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "Name of Reporter",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Incidence",
      dataIndex: "issue",
      key: "issue",
      render: (record) => <span className="capitalize">{record}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "center",

      render: (record, key) => {
        return (
          <Tag
            className="capitalize"
            color={
              record.toLowerCase() === "escalated"
                ? "red"
                : record.toLowerCase() === "pending"
                ? "yellow"
                : record.toLowerCase() === "completed"
                ? "green"
                : "blue"
            }
          >
            {record}
          </Tag>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Action",
      key: "action",
      align: "center",

      render: (record, key) => {
        return (
          <Button
            className="ant-btn-link"
            onClick={() => {
              setCurrReport(record);
              toggleReportModal();
            }}
            type="link"
          >
            view
          </Button>
        );
      },
    },
  ];

  return (
    <CardWrapper title="Reported Cases">
      <Table
        loading={loading}
        className="table-responsive"
        columns={columns}
        dataSource={filteredMessages?.slice(0, 5)?.map((msg, idx) => ({
          ...msg,
          rank: idx + 1,
          name: msg?.name,
          address: msg?.address,
          category: getEmergencyName(msg?.category),
          status: msg?.status,
          date: new Date(msg?.date_created).toLocaleString(),
        }))}
        pagination={false}
        bordered
      />

      {isReportModalOpen ? (
        <ViewReportModal
          visible={isReportModalOpen}
          closeModal={toggleReportModal}
          currReport={currReport}
        />
      ) : null}
    </CardWrapper>
  );
};

export default ReportedCases;
