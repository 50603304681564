import React, { useEffect, useState } from "react";
import { Table, message, Button } from "antd";
import CardWrapper from "components/cardWrapper";
import SearchBar from "components/searchbar";
import { TableWrapper } from "./reportStyles";
import { OptionsMenu } from "./reportStyles";
import useToggle from "hooks/useToggle";
import { useIssuesContext } from "context/issues";
import AddIssueModal from "./addIssueModal";
import EditIssueModal from "./editIssueModal";
import { useHistory, useRouteMatch } from "react-router-dom";

const Incidents = () => {
  const [currIssue, setCurrIssue] = useState();
  const [filteredIssues, setFilteredIssues] = useState([]);

  const { loading, data: issues, refetchData } = useIssuesContext();

  const [isAddIssueModalOpen, toggleAddIssueModal] = useToggle();
  const [isEditIssueModalOpen, toggleEditIssueModal] = useToggle();

  let { path } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (issues) {
      setFilteredIssues(
        issues?.map((issue, idx) => ({
          ...issue,
          rank: idx + 1,
          name: issue?.name,
          numOfQuestions: issue?.question_list.length,
          date: new Date(issue?.date_created).toLocaleString(),
        }))
      );
    }
  }, [issues]);

  const columns = [
    {
      title: "S/N",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "Name of Incident",
      dataIndex: "name",
      key: "name",
      width: "200px",
    },
    {
      title: "Number of Occurrences",
      dataIndex: "case_count",
      key: "case_count",
      align: "center",
      width: "200px",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   align: "center",

    //   render: (record, key) => {
    //     return (
    //       <Button
    //         className="ant-btn-link"
    //         onClick={() => {
    //           // setCurrReport(record);
    //           // toggleReportModal();
    //         }}
    //         type="link"
    //       >
    //         view
    //       </Button>
    //     );
    //   },
    // },
  ];

  const handleSearch = (value) => {
    const originalData = [
      ...issues.map((issue, idx) => ({
        ...issue,
        key: idx + 1,
        rank: idx + 1,
        name: issue?.name,
        numOfQuestions: issue?.question_list.length,
        date: new Date(issue?.date_created).toLocaleString(),
      })),
    ];

    if (!value) setFilteredIssues(originalData);

    const result = originalData.filter((d) => {
      return Object.keys(d).some((key) =>
        String(d[key]).toLowerCase().includes(value.toLowerCase())
      );
    });

    if (result.length === 0) {
      message.warning("No Data Found!", 5);
    }

    setFilteredIssues(result);
  };

  return (
    <section>
      <CardWrapper
        title={`All Incidences (${filteredIssues.length})`}
        extra={
          <Button onClick={toggleAddIssueModal} type="primary">
            Add New Incident
          </Button>
        }
      >
        <TableWrapper>
          <OptionsMenu>
            {/* <Space>
                <Button type="primary">Copy</Button>
                <Button type="primary">CSV</Button>
                <Button type="primary">Excel</Button>
                <Button type="primary">PDF</Button>
                <Button type="primary">Print</Button>
              </Space> */}

            <SearchBar handleSearch={handleSearch} />
          </OptionsMenu>

          <Table
            loading={loading}
            onRow={(record) => {
              return {
                onClick: () => {
                  return history.push(`${path}/${record.id}`);
                },
              };
            }}
            className="table-responsive incident-table"
            columns={columns}
            dataSource={filteredIssues}
          />
        </TableWrapper>
      </CardWrapper>

      {isAddIssueModalOpen ? (
        <AddIssueModal
          visible={isAddIssueModalOpen}
          closeModal={toggleAddIssueModal}
          refetchData={refetchData}
        />
      ) : null}

      {isEditIssueModalOpen ? (
        <EditIssueModal
          currIssue={currIssue}
          visible={isEditIssueModalOpen}
          closeModal={toggleEditIssueModal}
          refetchData={refetchData}
        />
      ) : null}
    </section>
  );
};

export default Incidents;
