import React, { useState } from "react";
import { Button, Checkbox, Col, Form, Modal } from "antd";
import InputStyled, { InputNumberStyled } from "components/inputStyled";
import IssueServices from "config/services/IssuesServices";

const FormItem = Form.Item;

const AddNewQuestionModal = ({ visible, closeModal, issueId, refetchData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([
    {
      id: Date.now(),
      question: "",
      flow_number: "",
      is_image: false,
    },
  ]);

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      setIsLoading(true);

      const res = await IssueServices.addNewQuestion(issueId, [values]);

      console.log(res);

      if (res.message === "success") {
        Modal.success({
          title: "Question added successfully",
          onOk: async () => {
            await refetchData();
            closeModal();
          },
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(error);
    }
  };

  return (
    <Modal
      width={600}
      title={<strong>Add New Question</strong>}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          loading={isLoading}
          key="create"
          type="primary"
          onClick={handleSubmit}
        >
          Ok
        </Button>,
      ]}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          is_image: false,
        }}
      >
        <Col>
          <FormItem
            label="Question"
            name="question"
            rules={[
              {
                message: "Please enter question!",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Enter Question" />
          </FormItem>
        </Col>

        <Col>
          <FormItem
            label="Flow Number"
            name="flow_num"
            rules={[
              {
                message: "Please enter flow number!",
                required: true,
                type: "number",
              },
            ]}
          >
            <InputNumberStyled placeholder="Flow Number" />
          </FormItem>
        </Col>

        <Col>
          <FormItem valuePropName="checked" label="Has Image" name="is_image">
            <Checkbox defaultChecked={false}>Has Image</Checkbox>
          </FormItem>
        </Col>
      </Form>
    </Modal>
  );
};

export default AddNewQuestionModal;
