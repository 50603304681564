import React from "react";
import CardWrapper from "components/cardWrapper";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      // text: "Chart.js Bar Chart",
    },
  },
};

const labels = [
  "Police",
  "LASAMBUS",
  "Fire Service",
  "LASTMA",
  "May",
  "LASEMA",
  "SEHMU",
];

export const data = {
  labels,
  datasets: [
    // {
    //   label: "Dataset 1",
    //   data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
    //   backgroundColor: "rgba(255, 99, 132, 0.5)",
    // },
    {
      label: "Dataset 2",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const BarChart = () => {
  return (
    <CardWrapper
      // height={400}
      title="Review of Reported Cases per LGA"
    >
      <Bar options={options} data={data} />
    </CardWrapper>
  );
};

export default BarChart;
