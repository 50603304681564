import React, { useEffect, useState } from "react";
import { Table, Button, Tag, message, Space } from "antd";
import CardWrapper from "components/cardWrapper";
import SearchBar from "components/searchbar";
import { TableWrapper } from "./reportStyles";
import { OptionsMenu } from "./reportStyles";
import useToggle from "hooks/useToggle";
import ViewReportModal from "./viewReportModal";
import useAsync from "hooks/useAsync";
import MessageServices from "config/services/MessageServices";
// import ViewReportModal from "views/admin/reports/reportModal";

const Reports = () => {
  const [currReport, setCurrReport] = useState();
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [emergencyType, setEmergencyType] = useState("All");

  // const { loading, allMessages } = useMessageContext();

  const {
    loading,
    data: { data: assignedMessages },
  } = useAsync(MessageServices.getAssignedMessages);

  const [isReportModalOpen, toggleReportModal] = useToggle();

  const assignedMessagesData = assignedMessages?.map((message, idx) => ({
    rank: idx + 1,
    name: message.case_detail.name,
    address: message.case_detail.address,
    issue: message.issue,
    status: message.case_detail.status,
    date_escalated: new Date(
      message.case_detail.date_escalated
    ).toLocaleString(),
    ...message,
  }));

  useEffect(() => {
    if (assignedMessages) {
      setFilteredMessages(
        assignedMessages?.map((message, idx) => ({
          rank: idx + 1,
          name: message.case_detail.name,
          address: message.case_detail.address,
          issue: message.issue,
          status: message.case_detail.status,
          date_escalated: new Date(
            message.case_detail.date_escalated
          ).toLocaleString(),
          ...message,
        }))
      );
    }
  }, [assignedMessages]);

  const getStatus = (status) => {
    if (status === "pending") return "yellow";
    if (status === "responded") return "orange";
    if (status === "arrived") return "blue";
    if (status === "complete") return "green";
    return "blue";
  };

  const columns = [
    {
      title: "S/N",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "Name of Reporter",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Incident Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Issue",
      dataIndex: "issue",
      key: "issue",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",

      render: (record, key) => {
        return (
          <Tag className="capitalize" color={getStatus(record.toLowerCase())}>
            {record}
          </Tag>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date_escalated",
      key: "date_escalated",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (record, key) => {
        return (
          <Button
            className="ant-btn-link"
            onClick={() => {
              setCurrReport(record);
              toggleReportModal();
            }}
            type="link"
          >
            view
          </Button>
        );
      },
    },
  ];

  const handleFilter = (value) => {
    if (value === "All") {
      setEmergencyType(value);
      setFilteredMessages(
        assignedMessages?.map((message, idx) => ({
          rank: idx + 1,
          name: message.case_detail.name,
          address: message.case_detail.address,
          issue: message.issue,
          status: message.case_detail.status,
          date_escalated: new Date(
            message.case_detail.date_escalated
          ).toLocaleString(),
          ...message,
        }))
      );
      return;
    }

    setEmergencyType(value);
    const result = [...assignedMessagesData]?.filter(
      (message, idx) =>
        message?.case_detail?.category?.toLowerCase() === value.toLowerCase()
    );
    // console.log("Result ", { assignedMessagesData, result });

    setFilteredMessages(
      result?.map((message, idx) => ({
        rank: idx + 1,
        name: message.case_detail.name,
        address: message.case_detail.address,
        issue: message.issue,
        status: message.case_detail.status,
        date_escalated: new Date(
          message.case_detail.date_escalated
        ).toLocaleString(),
        ...message,
      }))
    );
  };

  const handleSearch = (value) => {
    const originalData = [
      ...assignedMessagesData.map((d, i) => ({
        ...d,
        key: i + 1,
      })),
    ];

    if (!value) setFilteredMessages(originalData);

    const result = originalData.filter((d) => {
      return Object.keys(d).some((key) =>
        String(d[key]).toLowerCase().includes(value.toLowerCase())
      );
    });

    if (result.length === 0) {
      message.warning("No Data Found!", 5);
    }

    setFilteredMessages(result);
  };

  return (
    <section>
      <CardWrapper
        title={`All Incidents (${filteredMessages.length})`}
        extra={
          <Space>
            <Button
              onClick={() => handleFilter("All")}
              type={emergencyType === "All" ? "primary" : "ghost"}
            >
              All
            </Button>
            <Button
              onClick={() => handleFilter("emergency")}
              type={emergencyType === "emergency" ? "primary" : "ghost"}
            >
              Emergency
            </Button>
            <Button
              onClick={() => handleFilter("non_emergency")}
              type={emergencyType === "non_emergency" ? "primary" : "ghost"}
            >
              Non-emergency
            </Button>
            <Button
              onClick={() => handleFilter("hoax")}
              type={emergencyType === "hoax" ? "primary" : "ghost"}
            >
              Hoax
            </Button>
          </Space>
        }
      >
        <TableWrapper>
          <OptionsMenu>
            {/* <Space>
              <Button type="primary">Copy</Button>
              <Button type="primary">CSV</Button>
              <Button type="primary">Excel</Button>
              <Button type="primary">PDF</Button>
              <Button type="primary">Print</Button>
            </Space> */}

            <SearchBar handleSearch={handleSearch} />
          </OptionsMenu>

          <Table
            loading={loading}
            className="table-responsive"
            columns={columns}
            dataSource={filteredMessages}
          />
        </TableWrapper>
      </CardWrapper>

      {isReportModalOpen ? (
        <ViewReportModal
          visible={isReportModalOpen}
          closeModal={toggleReportModal}
          currReport={currReport}
        />
      ) : null}
    </section>
  );
};

export default Reports;
