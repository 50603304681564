import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function DisplayBarChart({ data }) {
  const chartDataset = {
    labels: Object.keys(data?.lga_data),
    datasets: [
      {
        label: data?.issue,
        data: Object.values(data?.lga_data),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <div>
      <h2 style={{ marginTop: "3rem", textAlign: "center" }}>
        {data?.issue} by L.G.A
      </h2>

      <Bar
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
            },
          },
        }}
        data={chartDataset}
      />
    </div>
  );
}

export default DisplayBarChart;
