import React, { useState } from "react";
import { Button, Form, Select, message } from "antd";
import AdminServices from "config/services/AdminServices";
import CardWrapper from "components/cardWrapper";
import { useUserContext } from "context/user";
import Spinner from "components/spinner";

const Option = Select.Option;

const ResetPassword = () => {
  const [selectedUser, setSelectedUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);

  const { loading: isDataLoading, agents, escalators } = useUserContext();

  const [form] = Form.useForm();

  const handleSubmit = () => {
    setLoading(true);
    AdminServices.adminResetUserPassword({ username: selectedUser })
      .then((data) => {
        setLoading(false);
        message.success("Password reset successful.");
        setSelectedUser("");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  if (isDataLoading) {
    return (
      <CardWrapper>
        <Spinner />
      </CardWrapper>
    );
  }

  return (
    <section>
      <CardWrapper title="Reset User Password">
        <Form
          wrapperCol={{ span: 12 }}
          className="mt-2"
          layout="vertical"
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item
            name="user"
            label="User"
            rules={[
              {
                required: true,
                message: "Please select user",
              },
            ]}
          >
            <Select
              loading={isDataLoading}
              value={selectedUser}
              onSelect={(value) => {
                console.log(value);
                setSelectedUser(value);
              }}
              showSearch
            >
              {agents?.map((agent) => (
                <Option
                  key={agent.id}
                  value={agent.username}
                >{`${agent.first_name} ${agent.last_name}`}</Option>
              ))}
              {escalators?.map((esclator) => (
                <Option
                  key={esclator.id}
                  value={esclator.username}
                >{`${esclator.first_name} ${esclator.last_name}`}</Option>
              ))}
            </Select>
          </Form.Item>

          <Button
            loading={loading}
            htmlType="submit"
            size="large"
            type="primary"
          >
            Save Changes
          </Button>
        </Form>
      </CardWrapper>
    </section>
  );
};

export default ResetPassword;
