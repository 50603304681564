import React from "react";
import Charts from "./charts";
import ReportedCases from "./reportedCases";
import SummaryCards from "./summaryCards";

const Dashboard = () => {
  return (
    <section>
      <SummaryCards />
      <Charts />
      <ReportedCases />
    </section>
  );
};

export default Dashboard;
