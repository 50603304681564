import { Layout, Menu } from "antd";
import styled from "styled-components";

const { Header } = Layout;

const HeaderStyled = styled(Header)`
  .wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .logo-wrapper {
      display: flex;
      align-items: center;
      width: 120px;
      height: 60px;

      img {
        width: 100%;
        height: 100%;
      }

      .burgermenu-btn {
        margin-right: 1rem;
        background: none;
        border: 0;
        padding: 0;
        cursor: pointer;
        display: none;
      }
    }
  }

  .nav-searchbar-mobile {
    @media screen and (min-width: 992px) {
      display: none;
    }
  }

  .nav-searchbar {
    @media screen and (max-width: 992px) {
      display: none;
    }
  }
`;

const MenuStyled = styled(Menu)`
  .ant-menu-item-selected {
    background: #373075;
    // color: #fff;
  }
`;

const LayoutStyled = styled(Layout)`
  .ant-layout-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    background-color: #fff;
    justify-content: space-between;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 992px) {
      height: unset;
      flex-direction: column;
      padding: 20px 24px;
    }
  }

  .ant-layout.ant-layout-has-sider {
    min-height: calc(100vh - 80px);
    margin-top: 80px;

    .ant-layout-sider {
      position: fixed;
      left: 0;
      bottom: 0;
      top: 80px;
      background-color: #fff;

      .ant-layout-sider-children {
        margin-top: 3rem;

        .btn-log-out {
          position: absolute;
          bottom: 2rem;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      @media screen and (max-width: 992px) {
        left: -300px;
      }
    }
  }
`;

const ContentLayout = styled(Layout)`
  margin-left: 300px;

  .site-layout-background {
    padding: 24px 24px 3rem;
    margin: 0;
    min-height: 280px;
  }

  @media screen and (max-width: 992px) {
    margin-left: 0;
  }
`;

const NavItemsStyled = styled.nav`
  display: flex;
  align-items: center;

  .nav-item-btn {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    margin-left: 2rem;

    :first-child {
      margin-left: 0;
      display: flex;
    }
  }
`;

const DropdownItemStyled = styled.div`
  position: relative;
  margin-left: ${({ ml }) => `${ml}rem` ?? "2rem"};
  cursor: pointer;

  .content {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    background: #ffffff;
    border: 1px solid #dbdee7;
    border-radius: 6px;
    position: absolute;
    width: 160px;
    margin-top: 5px;
    right: 0;

    a {
      display: block;
    }

    li {
      padding: 0 1rem;
      cursor: pointer;
      height: 40px;
      display: flex;
      align-items: center;

      &:hover {
        background-color: #f5f6f8;
        color: ${({ theme }) => theme["primary-color"]};
      }
    }
  }
`;

const AvatarButton = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  background: none;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }

  .text {
    margin: 0 12px;
  }
`;

export {
  MenuStyled,
  HeaderStyled,
  LayoutStyled,
  ContentLayout,
  NavItemsStyled,
  DropdownItemStyled,
  AvatarButton,
};
