import { Link } from "react-router-dom";
import styled from "styled-components";

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
`;

const ImagegPlaceholder = styled.div`
  width: 150px;
  height: 150px;
  margin-bottom: 1rem;
  border-radius: 50%;
  background-color: #ccc;
`;

const UploadButton = styled(Link)`
  text-decoration: underline;
`;

export { AvatarWrapper, ImagegPlaceholder, UploadButton };
