import React from "react";
import { Button, Modal, Tag } from "antd";
import styled from "styled-components";
import { useEscalatorContext } from "context/escalators";
import useAsync from "hooks/useAsync";
import MessageServices from "config/services/MessageServices";
import { LoadingOutlined } from "@ant-design/icons";
import { CustomTableWrapper } from "./reportStyles";

const StyledModal = styled(Modal)`
  .title {
    strong {
      margin-right: 0.4rem;
    }
  }
`;

const Flex = styled.span`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
`;

const ImgWrapper = styled.div`
  display: flex;

  .box {
    max-width: 300px;

    img {
      width: 100%;
      height: auto;
    }
  }
`;

const ViewReportModal = ({ visible, closeModal, currReport }) => {
  const { data: agencies } = useEscalatorContext();

  const {
    loading,
    data: { data },
  } = useAsync(() => MessageServices.getReport(currReport.id));

  console.log(data);

  let responder_reports = data?.responder_reports;

  console.log({ responder_reports });

  // const selectedEscalators = currReport?.escalators?.map((escalatorId) => {
  //   const result = agencies.find((agency) => agency.id === escalatorId);

  //   return {
  //     ...result,
  //     id: result.id,
  //     name: result.name,
  //     acronym: result.acronym,
  //   };
  // });

  const selectedEscalators = currReport?.agency_detail?.join(", ");

  const record = currReport.status;

  const getStatus = (status) => {
    if (status === "escalated") return "red";
    if (status.toLowerCase() === "pending") return "yellow";
    if (
      status.toLowerCase() === "completed" ||
      status.toLowerCase() === "complete"
    )
      return "green";
    return "blue";
  };

  return (
    <StyledModal
      title={<strong>Report Details</strong>}
      visible={visible}
      onCancel={closeModal}
      width={800}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button key="create" type="primary" onClick={closeModal}>
          Ok
        </Button>,
      ]}
      destroyOnClose
    >
      <p className="title">
        <strong>Name of Reporter: </strong>
        {currReport.name}
      </p>

      <p className="title">
        <strong>Emergency: </strong>
        {currReport.issue}
      </p>

      <p className="title">
        <strong>Address: </strong>
        {currReport.address}
      </p>

      <p className="title">
        <strong>Status: </strong>
        <Tag className="capitalize" color={getStatus(record.toLowerCase())}>
          {record}
        </Tag>
      </p>

      <p>
        <strong>Phone Number: </strong>
        {currReport.phone}
      </p>

      <p>
        <strong>Landmark: </strong>
        {currReport.landmark}
      </p>

      <p>
        <strong>L.G.A: </strong>
        {currReport.local_gov}
      </p>

      <p>
        <strong>Provider: </strong>
        {currReport.provider}
      </p>

      {/* 
      <p className="title">
        <strong>Description: </strong>
        {currReport?.description || "--"}
      </p> */}
      {/* 
      <p className="third">
        <p className="title">
          <strong>Pictures:</strong>
        </p>

        {currReport.image_url1 || currReport.image_url2 ? (
          <ImgWrapper>
            {currReport.image_url1 && (
              <div className="box">
                <img src={currReport.image_url1} alt={currReport.issue} />
              </div>
            )}

            {currReport.image_url2 && (
              <div className="box">
                <img src={currReport.image_url2} alt={currReport.issue} />
              </div>
            )}
          </ImgWrapper>
        ) : null}
      </p> */}

      <p className="title">
        <strong>Agent Note: </strong>
        {currReport?.agent_note || "--"}
      </p>

      <p>
        <strong>Selected Escalators: </strong>

        <span>
          {/* {selectedEscalators?.map((d) => (
            <p key={d.id}>{d.acronym}</p>
          ))} */}

          {selectedEscalators}
        </span>
      </p>

      <p>
        <strong>Date Created: </strong>
        <p>{new Date(currReport.date_created).toLocaleString()}</p>
      </p>

      <p>
        <strong>Date Escalated: </strong>
        <p>{new Date(currReport.date_escalated).toLocaleString()}</p>
      </p>

      {currReport?.response_data?.length > 1 ? (
        <CustomTableWrapper>
          <tr>
            <th>Question</th>
            <th>Answer</th>
          </tr>

          {currReport.response_data.map((response) => (
            <tr>
              <td>{response.question}</td>
              <td>
                {response.answer.startsWith("https://") ? (
                  <a
                    className="underline"
                    href={response.answer}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here to view link
                  </a>
                ) : (
                  response.answer
                )}
              </td>
            </tr>
          ))}
        </CustomTableWrapper>
      ) : null}

      {record.toLowerCase() === "archived" && (
        <p>
          <strong>Reason for Archive: </strong> <br />
          {currReport.archive_reason}
        </p>
      )}

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingOutlined height={40} width={40} />
        </div>
      ) : responder_reports?.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "300px",
          }}
        >
          <p>No Reports Available for this Incidence</p>
        </div>
      ) : (
        responder_reports?.map((report) => (
          <div>
            <h2>
              Report by {report?.agency?.acronym},
              {` ${report?.first_responder?.local_gov} LGA`}
            </h2>

            <ul style={{ padding: 0, listStyle: "none" }}>
              <li>
                <strong>Escalator Note: </strong>

                {report.escalator_note}
              </li>

              <li>
                <strong>Responder Status: </strong>

                <Tag
                  className="capitalize"
                  color={getStatus(report.responder_status)}
                >
                  {report.responder_status}
                </Tag>
              </li>

              <li>
                <strong>Assigned Date: </strong>

                {new Date(report.assigned_date).toLocaleString()}
              </li>

              {report?.reports?.map((rep) => (
                <li
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  <p>
                    <strong>Situation Report: </strong>
                    {rep.situation_report}
                  </p>

                  {(rep.image_url1 || rep.image_url2) && (
                    <div style={{ minHeight: "300px", maxWidth: "300px" }}>
                      <img
                        src={rep.image_url1 || rep.image_url2}
                        alt="Incidence"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))
      )}
    </StyledModal>
  );
};

export default ViewReportModal;
