import React from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import Reports from "./reports";
import Alert from "./alerts";
import Login from "./login";
import MessageProvider from "context/messages";
import AgentLayout from "components/layout/agentsLayout";
import EscalatorProvider from "context/escalators";
import Profile from "./profile";
import IssuesProvider from "context/issues";
import ChangePassword from "views/changePassword";

const Agents = () => {
  let { path } = useRouteMatch();

  return (
    <MessageProvider>
      <EscalatorProvider>
        <IssuesProvider>
          <AgentLayout>
            <Switch>
              <Route exact path={path} component={Login} />
              <Route path={`${path}/login`} component={Login} />
              <Route path={path + "/d/incidents"} component={Alert} />
              <Route path={path + "/d/reports"} component={Reports} />
              {/* <Route path={path + "/d/messages"} component={Messages} /> */}
              <Route path={path + "/d/profile"} component={Profile} />
              <Route
                path={path + "/d/change-password"}
                component={ChangePassword}
              />

              <Redirect path="/*" to={{ pathname: path + "/d" }} />
            </Switch>
          </AgentLayout>
        </IssuesProvider>
      </EscalatorProvider>
    </MessageProvider>
  );
};

export default Agents;
