import React, { useState } from "react";
import { Button, Form, Input, message, Modal, Select } from "antd";
import MessageServices from "config/services/MessageServices";
import styled from "styled-components";
import { useMessageContext } from "context/messages";
import { useUserContext } from "context/user";
import { isValidURL } from "utils/helpers";
import FormItem from "antd/lib/form/FormItem";

const { Option } = Select;

const StyledModal = styled(Modal)`
  font-size: 16px;

  .text-underline {
    text-decoration: underline;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mb-min {
    margin-bottom: 10px;
  }

  .mb-1 {
    margin-bottom: 1rem;
  }

  .mb-2 {
    margin-bottom: 2rem;
  }

  .title {
    strong {
      margin-right: 0.4rem;
    }
  }

  footer {
    padding: 1.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .assign-btn {
      width: 300px;
    }
  }

  .message-content {
    background-color: #fff;
  }

  .fourth {
    margin: 1rem 0;
    .title {
      span {
        display: inline-block;
        width: 200px;
        strong {
          display: block;
        }
      }
    }
  }

  .select-first-responder::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }

  .box {
    width: 300px;
    height: 300px;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const ImgWrapper = styled.div`
  display: flex;

  .box {
    max-width: 300px;

    img {
      width: 100%;
      height: auto;
    }
  }
`;

const StyledForm = styled(Form)``;

const MessageDetailModal = ({
  currMessage,
  visible,
  closeModal,
  refetchData,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedFirstResponder, setSelectedFirstResponder] = useState("");
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [category, setCategory] = useState(currMessage?.category);

  const { refetchData: refetchEscalatedMessages } = useMessageContext();
  const { firstResponders, agencies } = useUserContext();

  const [form] = Form.useForm();

  const makeRequest = async () => {
    try {
      // if (category === "emergency" || category === "hoax") {
      const category_payload = {
        category,
        agent_note: "",
        additional_agencies: selectedAgencies,
      };

      const response_one = await MessageServices.escalateMessage(
        currMessage?.id,
        category_payload
      );

      // console.log("Response from emergency request ", response_one);

      if (category === "non_emergency" || category === "hoax") {
        if (response_one.message === "successful") {
          Modal.success({
            title:
              category === "hoax"
                ? "Emergency category has been saved"
                : "Escalated successfully",
            onOk: () => {
              refetchEscalatedMessages();
              refetchData();
              closeModal();
            },
          });
        }

        // }

        setLoading(false);
        return;
      }

      const payload = {
        // escalator_note: values.escalator_note,
        ...(selectedFirstResponder && { responder: selectedFirstResponder }),
        case: currMessage.id,
      };

      const response = await MessageServices.assignMessage(payload);

      // console.log("Main response ", response);

      setLoading(false);

      if (response.message === "success" || response.status === 204) {
        Modal.success({
          title: `Emergency has been ${
            category === "non_emergency" ? "escalated" : "assigned"
          } successfully`,
          onOk: () => {
            refetchEscalatedMessages();
            refetchData();
            closeModal();
          },
        });
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const handleMessageAssignment = async () => {
    setLoading(true);

    const values = await form.validateFields();

    Modal.confirm({
      title: "Are you sure?",
      onCancel: () => {
        setLoading(false);
      },
      onOk: () => makeRequest(),
    });
  };

  const handleSelectFirstResponder = (val) => {
    setSelectedFirstResponder(val);
  };

  const handleSelectAgencies = (val) => {
    setSelectedAgencies(val);
  };

  const filteredAgencies = [];

  agencies?.forEach((agency) => {
    if (!currMessage?.agency_detail?.find((d) => agency?.acronym === d)) {
      filteredAgencies.push(agency);
    }
  });

  return (
    <StyledModal
      width={800}
      visible={visible}
      onCancel={closeModal}
      title={null}
      footer={null}
      closable={false}
    >
      <h2 className="header">Details</h2>

      <div className="message-content">
        <div className="first">
          <p className="title">
            <strong>Name of Reporter: </strong>
            {currMessage.name}
          </p>
          <p className="title">
            <strong>Emergency: </strong>
            {currMessage.issue}
          </p>

          <p className="title">
            <strong>Location: </strong>
            {currMessage.address}
          </p>

          <p className="title">
            <strong>Phone Number:</strong>
            <span>{currMessage.phone}</span>
          </p>

          <p className="title">
            <strong>LGA:</strong>
            <span>{currMessage.local_gov || "--"}</span>
          </p>

          <p className="title">
            <strong>Nearest Landmark:</strong>
            <span>{currMessage.landmark || "--"}</span>
          </p>
        </div>

        <div className="second">
          <p className="title">
            <strong>Agent Note:</strong>
            {currMessage?.agent_note || "--"}
          </p>
        </div>

        <div className="second">
          <p className="title">
            <strong>Agencies escalated to: </strong>
            {currMessage?.agency_detail?.toString() || "--"}
          </p>
        </div>

        {/* <div className="second">
          <p className="title">
            <span>Description:</span>
            <strong>{currMessage?.description || "--"}</strong>
          </p>
        </div>

        <div className="third">
          <p>Pictures</p>

          {currMessage.image_url1 || currMessage.image_url2 ? (
            <ImgWrapper>
              {currMessage.image_url1 && (
                <div className="box">
                  <img src={currMessage.image_url1} alt={currMessage.issue} />
                </div>
              )}

              {currMessage.image_url2 && (
                <div className="box">
                  <img src={currMessage.image_url2} alt={currMessage.issue} />
                </div>
              )}
            </ImgWrapper>
          ) : null}
        </div> */}

        <p>
          <strong>Date of Incidence: </strong>
          {new Date(currMessage.date_created).toLocaleString() || "--"}
        </p>

        <p>
          <strong>Date of Escalation: </strong>
          {new Date(currMessage.date_escalated).toLocaleString() || "--"}
        </p>

        <div>
          <h2 className="text-underline">Conversation</h2>

          {currMessage.response_data.map((response) => (
            <div className="mb-2">
              <p className="mb-min">
                <strong>Question: </strong>
                {response.question}
              </p>

              <p>
                <strong>Answer: </strong>
                {isValidURL(response.answer) ? (
                  <div className="box">
                    <img src={response.answer} alt={currMessage.issue} />
                  </div>
                ) : (
                  response.answer
                )}
              </p>
            </div>
          ))}
        </div>

        <div className="fourth">
          <p>
            <strong className="select-first-responder">
              Select Emergency Category
            </strong>
          </p>

          <Select
            style={{ minWidth: "300px" }}
            disabled={currMessage?.category}
            value={category}
            defaultValue={currMessage?.category}
            onChange={(value) => {
              setCategory(value);
            }}
            placeholder="Select Category"
            className="capitalize"
          >
            {["emergency", "non_emergency", "hoax"].map((issue) => (
              <Option className="capitalize" key={issue} value={issue}>
                {issue.split("_").join("-")}
              </Option>
            ))}
          </Select>
        </div>

        {category === "emergency" && (
          <div className="fourth">
            <p>
              <strong className="select-first-responder">
                Select First Responders
              </strong>
            </p>

            <Select
              style={{ minWidth: "300px" }}
              onChange={handleSelectFirstResponder}
            >
              {firstResponders.map((d) => (
                <Option
                  key={d.id}
                  value={d.id}
                >{`${d.username}`}</Option>
              ))}
            </Select>
          </div>
        )}

        {category === "non_emergency" && (
          <div className="fourth">
            <p>
              <strong className="select-first-responder">
                Select Agencies
              </strong>
            </p>

            <Select
              style={{ minWidth: "300px" }}
              onChange={(value) => {
                console.log("Selected Agency ", value);
                handleSelectAgencies(value);
              }}
              mode="multiple"
            >
              {filteredAgencies?.map((d) => (
                <Option key={d.id} value={d.id}>
                  {d?.acronym}
                </Option>
              ))}
            </Select>
          </div>
        )}

        {/* 
        <StyledForm layout="vertical" className="form" form={form}>
          <Form.Item
            label={<strong>Escalator Note</strong>}
            name="escalator_note"
            // rules={[
            //   {
            //     message: "agent note is required!",
            //     required: true,
            //   },
            // ]}
          >
            <Input.TextArea rows={4} placeholder="Enter Escalator Note" />
          </Form.Item>
        </StyledForm> */}
      </div>

      <footer>
        <Button
          className="assign-btn"
          size="large"
          loading={loading}
          onClick={handleMessageAssignment}
          type="primary"
        >
          {category === "emergency"
            ? "Assign"
            : category === "non_emergency"
            ? "Escalate"
            : category === "hoax"
            ? "Save"
            : "Assign"}
        </Button>
      </footer>
    </StyledModal>
  );
};

export default MessageDetailModal;
