import React, { useEffect, useState } from "react";
import {
  Button,
  Calendar,
  Card,
  Col,
  Empty,
  Pagination,
  Row,
  Select,
  Space,
  notification,
} from "antd";
import useToggle from "hooks/useToggle";
import AlertItem from "./alertItem";
import { FilterSelector, Legend, StyledCardWrapper } from "./alertStyles";
import AddCaseModal from "./addCaseModal";
import { useMessageContext } from "context/messages";
import MessageDetailModal from "../messageDetailModal";
import { onMessage, messaging } from "../../../firebase";
import MessageServices from "config/services/MessageServices";

const { Option } = Select;

const Alert = () => {
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currMessage, setCurrMessage] = useState({});
  const [filterCategory, setFilterCategory] = useState("All");
  const [total, setTotal] = useState(0);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredMessages, setFilteredMessages] = useState([]);

  // const {
  //   loading,
  //   data: { count, next: nextLink, previous: previousLink, results },
  //   fetchFilteredPendingMessages,
  //   refetchData,
  // } = useMessageContext();

  const refetchData = async () => {
    try {
      setLoading(true);

      let data;
      if (filterCategory === "All") {
        data = await MessageServices.getAllMessages(current, pageSize);
      } else if (filterCategory === "pending") {
        data = await MessageServices.getAllMessages(current, pageSize);
        const pendingResults = data.results.filter(
          (message) => message?.category === null
        );
        data.count = pendingResults.length;
        data.results = pendingResults;
      } else {
        data = await MessageServices.getAllMessages(
          current,
          pageSize,
          filterCategory
        );
      }

      setTotal(data?.count);
      setResults(data?.results);
      setFilteredMessages(data?.results);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchNextData = async () => {
      try {
        setLoading(true);

        let data;
        if (filterCategory === "All") {
          data = await MessageServices.getAllMessages(current, pageSize);
        } else if (filterCategory === "pending") {
          data = await MessageServices.getAllMessages(current, pageSize);
          const pendingResults = data.results.filter(
            (message) => message?.category === null
          );
          data.count = pendingResults.length;
          data.results = pendingResults;
        } else {
          data = await MessageServices.getAllMessages(
            current,
            pageSize,
            filterCategory
          );
        }

        setTotal(data?.count);
        setResults(data?.results);
        setFilteredMessages(data?.results);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchNextData();
  }, [current, pageSize, filterCategory]);

  // function onPanelChange(value) {
  //   const selectedYear = value.format("YYYY-MM-DD");
  //   fetchFilteredPendingMessages(selectedYear);
  // }

  const [isMessageDetailOpen, toggleMessageDetail] = useToggle();
  const [isAddCaseModalOpen, toggleAddCaseModal] = useToggle();

  onMessage(messaging, (payload) => {
    console.log("Foreground Message received. ", payload);

    notification.info({
      description: payload.notification.body,
      message: payload.notification.title,
    });

    refetchData();
  });

  const handleFilter = (value) => {
    setFilterCategory(value);
  };

  const handleToggleMessageDetail = (value) => {
    setCurrMessage(value);
    toggleMessageDetail();
  };

  const onChange = (pageNumber, pageSize) => {
    setCurrent(pageNumber);
    setPageSize(pageSize);
  };

  return (
    <section>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={24}>
          {loading ? (
            <Card loading={loading} />
          ) : (
            <StyledCardWrapper
              title="Incidents"
              // extra={
              //   <Space>
              //     <Button
              //       onClick={() => handleFilter("All")}
              //       type={filterCategory === "All" ? "primary" : "ghost"}
              //     >
              //       All
              //     </Button>
              //     <Button
              //       onClick={() => handleFilter("pending")}
              //       type={filterCategory === "pending" ? "primary" : "ghost"}
              //     >
              //       Pending
              //     </Button>
              //     <Button
              //       onClick={() => handleFilter("emergency")}
              //       type={filterCategory === "emergency" ? "primary" : "ghost"}
              //     >
              //       Emergency
              //     </Button>
              //     <Button
              //       onClick={() => handleFilter("non_emergency")}
              //       type={
              //         filterCategory === "non_emergency" ? "primary" : "ghost"
              //       }
              //     >
              //       Non-emergency
              //     </Button>
              //     <Button
              //       onClick={() => handleFilter("hoax")}
              //       type={filterCategory === "hoax" ? "primary" : "ghost"}
              //     >
              //       Hoax
              //     </Button>
              //   </Space>
              // }
            >
              <Row className="mb-2" justify="space-between">
                {/* <FilterSelector>
                  Filter By
                  <Select className="filter-selector">
                    <Option>Today</Option>
                    <Option>Yesterday</Option>
                  </Select>
                </FilterSelector> */}

                {/* <Button
                  onClick={toggleAddCaseModal}
                  className="alertAdd"
                  type="primary"
                >
                  <PlusOutlined />
                  Add New Case
                </Button> */}
              </Row>

              {filteredMessages?.length > 0 ? (
                filteredMessages?.map((message, i) => (
                  <AlertItem
                    key={message.id}
                    message={message}
                    idx={i + 1}
                    handleToggleMessageDetail={handleToggleMessageDetail}
                  />
                ))
              ) : (
                <Empty />
              )}
            </StyledCardWrapper>
          )}
        </Col>

        {loading ? null : (
          <div style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
            <Pagination
              current={current}
              pageSize={pageSize}
              total={total}
              onChange={onChange}
            />
          </div>
        )}

        {/* <Col lg={6}>
          <Card style={{ minHeight: "100vh" }}>
            <Calendar fullscreen={false} onPanelChange={onPanelChange} />

            <Legend>
              <h3 className="title">My Legend</h3>

              <div className="legend-item">
                <span className="bg-color blue"></span>
                <span className="text">Assigned</span>
              </div>

              <div className="legend-item">
                <span className="bg-color yellow"></span>
                <span className="text">Pending</span>
              </div>

              <div className="legend-item">
                <span className="bg-color pink"></span>
                <span className="text">Escalated</span>
              </div>

              <div className="legend-item">
                <span className="bg-color green"></span>
                <span className="text">Completed</span>
              </div>
            </Legend>
          </Card>
        </Col> */}
      </Row>

      {isAddCaseModalOpen ? (
        <AddCaseModal
          visible={isAddCaseModalOpen}
          closeModal={toggleAddCaseModal}
        />
      ) : null}

      {isMessageDetailOpen ? (
        <MessageDetailModal
          currMessage={currMessage}
          visible={isMessageDetailOpen}
          closeModal={toggleMessageDetail}
          refetchData={refetchData}
        />
      ) : null}
    </section>
  );
};

export default Alert;
