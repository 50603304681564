import React, { useState, useEffect } from "react";
import CardWrapper from "components/cardWrapper";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import requests from "config/services/httpService";
import { Select, Form } from "antd";

const Option = Select.Option;
const FormItem = Form.Item;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      // text: "Chart.js Bar Chart",
    },
  },
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const lgas = [
  "All",
  "Agege",
  "Ajeromi-Ifelodun",
  "Alimosho",
  "Amuwo-Odofin",
  "Badagry",
  "Apapa",
  "Epe",
  "Eti Osa",
  "Ibeju-Lekki",
  "Ifako-Ijaiye",
  "Ikeja",
  "Ikorodu",
  "Kosofe",
  "Lagos Island",
  "Mushin",
  "Lagos Mainland",
  "Ojo",
  "Oshodi-Isolo",
  "Shomolu",
  "Surulere Lagos State",
];

const labels = [
  "Police",
  "LASAMBUS",
  "Fire Service",
  "LASTMA",
  "May",
  "LASEMA",
  "SEHMU",
];

export const dataset = {
  labels,
  datasets: [
    {
      //   label: "Dataset 2",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const TotalReportedCases = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [filterValue, setFilterValue] = useState({
    lga: "All",
    month: "",
    year: "",
  });

  const chartDataset = {
    labels: Object.keys(data),
    datasets: [
      {
        label: "Total Reported Cases",
        data: Object.values(data),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const fetchDatasets = async () => {
    try {
      setIsLoading(true);

      if (!filterValue.lga && filterValue.month) {
        return;
      }

      let response;

      // reported_cases_by_issues

      if (
        filterValue.lga &&
        filterValue.lga !== "All" &&
        filterValue.month &&
        filterValue.year
      ) {
        const monthValue = months.findIndex((d) => d === filterValue.month);
        response = await requests.get(
          `/dashboard/reported_cases_by_issues?local_gov=${
            filterValue.lga
          }&month=${monthValue + 1}&year=${filterValue.year}`
        );
      }

      if (filterValue.lga === "All" && filterValue.month) {
        const monthValue = months.findIndex((d) => d === filterValue.month);

        response = await requests.get(
          `/dashboard/reported_cases_by_issues?month=${monthValue + 1}`
        );
      }

      if (filterValue.lga && !filterValue.month) {
        response = await requests.get(
          filterValue.lga === "All"
            ? `/dashboard/reported_cases_by_issues`
            : `/dashboard/reported_cases_by_issues?local_gov=${filterValue.lga}`
        );
      }

      if (filterValue.lga && filterValue.lga !== "All" && filterValue.year) {
        const monthValue = months.findIndex((d) => d === filterValue.month);
        response = await requests.get(
          `/dashboard/reported_cases_by_issues?local_gov=${filterValue.lga}&year=${filterValue.year}`
        );
      }

      // if (filterValue.lga === 'All' && !filterValue.month && !filterValue.year) {
      //   response = await requests.get(
      //     `/dashboard/escalated_cases_by_agency`
      //   );
      // }

      setIsLoading(false);

      console.log(response);

      if (response.message === "success") {
        const filteredData = {}
        for (let key in response.report_by_cases) {
          if (response.report_by_cases[key] > 0) {
            filteredData[key] = response.report_by_cases[key]
          }
        }

        setData(filteredData);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getYears = (num) => {
    const years = [];
    let currentYear = new Date().getFullYear();

    for (let index = 0; index < num; index++) {
      years.push(currentYear - index);
    }

    return years;
  };

  useEffect(() => {
    if (filterValue) {
      fetchDatasets();
    }
  }, [filterValue]);

  const handleSelectLGA = (val) => {
    setFilterValue({ ...filterValue, lga: val });
  };

  const handleSelectMonth = (val) => {
    setFilterValue({ ...filterValue, month: val });
  };

  const handleSelectYear = (val) => {
    setFilterValue({ ...filterValue, year: val });
  };

  return (
    <CardWrapper
      height={400}
      loading={isLoading}
      title="Total Reported Cases by Issues"
      extra={
        <div style={{ display: "flex" }}>
          <FormItem
            label="Filter by LGA"
            labelAlign="left"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Select
              defaultValue={filterValue.lga}
              onChange={handleSelectLGA}
              size="large"
              style={{ width: "200px", marginRight: "1rem" }}
            >
              {lgas.map((lga) => (
                <Option key={lga} value={lga}>
                  {lga}
                </Option>
              ))}
            </Select>
          </FormItem>

          <FormItem
            label="Filter by Month"
            labelAlign="left"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Select
              defaultValue={filterValue.month}
              onChange={handleSelectMonth}
              size="large"
              style={{ width: "200px", marginRight: "1rem" }}
            >
              {months.map((month, i) => (
                <Option key={month} value={month}>
                  {month}
                </Option>
              ))}
            </Select>
          </FormItem>

          <FormItem
            label="Filter by Year"
            labelAlign="left"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Select
              defaultValue={filterValue.year}
              onChange={handleSelectYear}
              size="large"
              style={{ width: "200px" }}
            >
              {getYears(10).map((year, i) => (
                <Option key={year} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </FormItem>
        </div>
      }
    >
      <Bar
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              // text: "Chart.js Bar Chart",
            },
          },
        }}
        data={chartDataset}
      />
    </CardWrapper>
  );
};

export default TotalReportedCases;
