import React from "react";
import { Input, InputNumber } from "antd";

const InputStyled = (props) => {
  return <Input size="large" {...props} />;
};

export const InputNumberStyled = (props) => {
  return <InputNumber size="large" style={{ width: "100%" }} {...props} />;
};

export default InputStyled;
