import React, { useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import CardWrapper from "components/cardWrapper";
import AdminServices from "config/services/AdminServices";

const ChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      setIsLoading(true);

      const res = await AdminServices.resetPassword(values);

      if (res.message === "Successfully saved password") {
        Modal.success({
          title: "Password reset successful",
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(error);
    }
  };

  return (
    <section>
      <CardWrapper title="Change Password">
        <Form
          wrapperCol={{ span: 12 }}
          className="mt-2"
          layout="vertical"
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item
            name="old_password"
            label="Current Password"
            rules={[
              {
                required: true,
                message: "Please input your current password!",
              },
            ]}
            hasFeedback
          >
            <Input.Password size="large" />
          </Form.Item>

          <Form.Item
            name="new_password"
            label="New Password"
            rules={[
              {
                required: true,
                message: "Please input a new password!",
              },
            ]}
            hasFeedback
          >
            <Input.Password size="large" />
          </Form.Item>

          <Form.Item
            name="confirm_password"
            label="Confirm Password"
            dependencies={["old_password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>

          <Button
            loading={isLoading}
            htmlType="submit"
            size="large"
            type="primary"
          >
            Save Changes
          </Button>
        </Form>
      </CardWrapper>
    </section>
  );
};

export default ChangePassword;
