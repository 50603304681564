import styled from "styled-components";

const OptionsMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const TableWrapper = styled.div`
  margin-top: 2rem;

  .ant-btn-link {
    color: #3383fd;
  }
`;

export { OptionsMenu, TableWrapper };
