import React, { useEffect, useState } from "react";
import { Table, Button, message } from "antd";
import CardWrapper from "components/cardWrapper";
import SearchBar from "components/searchbar";
import { TableWrapper } from "./firstStyles";
import useToggle from "hooks/useToggle";
import { useUserContext } from "context/user";
import AddFirstResponderModal from "./modals/addFirstResponderModal";
import { useEscalatorContext } from "context/escalators";
import ViewFirstResponderModal from "./modals/viewFirstResponderModal";

const FirstResponder = () => {
  const [currFirstResponder, setCurrFirstResponder] = useState();
  const [filteredResponders, setFilteredResponders] = useState([]);

  const { loading, firstResponders } = useUserContext();
  const { data: agencies } = useEscalatorContext();

  const [isAddFirstResponderModalOpen, toggleAddFirstResponderModal] =
    useToggle();
  const [isViewFirstResponderModalOpen, toggleViewFirstResponderModal] =
    useToggle();

  useEffect(() => {
    if (firstResponders) {
      setFilteredResponders(firstResponders);
    }
  }, [firstResponders]);

  const columns = [
    {
      title: "S/N",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Agency",
      dataIndex: "agency",
      key: "agency",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (record) => <span className="capitalize">{record}</span>,
    },
    {
      title: "Actions",
      key: "action",
      align: "center",
      render: (record) => (
        <Button
          type="link"
          onClick={() => {
            setCurrFirstResponder(record);
            toggleViewFirstResponderModal();
          }}
        >
          view
        </Button>
      ),

      // render: (record) => {
      //   return (
      //     <Space size="middle">
      //       <Button
      //         onClick={() => {
      //           setCurrFirstResponder(record);
      //           toggleEditFirstResponderModal();
      //         }}
      //       >
      //         <EditOutlined />
      //       </Button>

      //       <Button
      //         onClick={() => handleDeleteFirstResponderModal(record.id)}
      //         danger
      //       >
      //         <DeleteOutlined />
      //       </Button>
      //     </Space>
      //   );
      // },
    },
  ];

  const handleSearch = (value) => {
    const originalData = [
      ...firstResponders.map((d, i) => ({
        ...d,
        key: i + 1,
      })),
    ];

    if (!value) setFilteredResponders(originalData);

    const result = originalData.filter((d) => {
      return Object.keys(d).some((key) =>
        String(d[key]).toLowerCase().includes(value.toLowerCase())
      );
    });

    if (result.length === 0) {
      message.warning("No Data Found!", 5);
    }

    setFilteredResponders(result);
  };

  return (
    <section>
      <CardWrapper
        title={`All FirstResponders (${filteredResponders.length})`}
        extra={
          <Button onClick={toggleAddFirstResponderModal} type="primary">
            Add New First-Responder
          </Button>
        }
      >
        <SearchBar handleSearch={handleSearch} />

        <TableWrapper>
          <Table
            loading={loading}
            className="table-responsive"
            columns={columns}
            dataSource={filteredResponders?.map((d, idx) => ({
              ...d,
              rank: idx + 1,
              name: `${d.first_name} ${d.last_name}`,
              email: d.email,
              phone: d.phone,
              address: d.address,
              gender: d.gender,
              agency: agencies?.find((item) => d.agency === item.id)?.acronym,
            }))}
          />
        </TableWrapper>
      </CardWrapper>

      {isAddFirstResponderModalOpen ? (
        <AddFirstResponderModal
          visible={isAddFirstResponderModalOpen}
          closeModal={toggleAddFirstResponderModal}
        />
      ) : null}

      {isViewFirstResponderModalOpen ? (
        <ViewFirstResponderModal
          currFirstResponder={currFirstResponder}
          visible={isViewFirstResponderModalOpen}
          closeModal={toggleViewFirstResponderModal}
        />
      ) : null}
    </section>
  );
};

export default FirstResponder;
