import styled from "styled-components";

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 300px;

  form {
    display: flex;
    flex-grow: 1;

    label {
      flex-grow: 1;

      input {
        height: 40px;
        padding: 0 1rem;
        width: 100%;
        border: 1px solid #d9d9d9;
        border-radius: 12px 0px 0px 12px;

        :focus {
          outline: 0;
        }
      }
    }

    .search-btn {
      background: none;
      border: 0;
      width: 40px;
      height: 40px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #d9d9d9;
      border-radius: 0 12px 12px 0;
    }
  }
`;

export { SearchWrapper };
