import React, { useState, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Button, Form, Input, message } from "antd";
import { useHistory } from "react-router-dom";
import { AuthSection, FormWrapper } from "./loginStyles";
import InputStyled from "components/inputStyled";
import { useAuthContext } from "context/AuthContext";
import AdminServices from "config/services/AdminServices";

const FormItem = Form.Item;

const Login = () => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const history = useHistory();

  const {
    state: { adminInfo },
    dispatch,
  } = useAuthContext();

  console.log(adminInfo);

  const { url } = useRouteMatch();

  useEffect(() => {
    if (adminInfo && adminInfo.email) {
      history.push("/escalators/d/incidents");
    }
  }, [adminInfo, history, url]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      const res = await AdminServices.login({
        ...values,
        firebase_key: adminInfo.firebase_key,
      });

      setLoading(false);

      if (res) {
        setLoading(false);
        message.success("Login Success!");
        dispatch({ type: "USER_LOGIN", payload: res.data });
        localStorage.setItem("adminInfo", JSON.stringify(res.data));

        history.push("/escalators/d/incidents");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <AuthSection>
      <div className="bg-wrapper"></div>

      <FormWrapper>
        <div className="max-width-wrapper">
          <div className="content">
            <h2 className="title">Login</h2>
            <p className="desc">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
              aliquam, suscipit ultricies amet ac tincidunt morbi. Nulla
              volutpat sit.
            </p>

            <Form layout="vertical" form={form} onFinish={handleSubmit}>
              <FormItem
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not a valid E-mail!",
                  },
                  {
                    message: "Please input your Email!",
                    required: true,
                  },
                ]}
              >
                <InputStyled placeholder="Email Address" />
              </FormItem>

              <FormItem
                label="Password"
                name="password"
                rules={[
                  { message: "Please input your password", required: true },
                ]}
              >
                <Input.Password size="large" placeholder="Password" />
              </FormItem>

              <div className="btn-submit-wrapper">
                <Button
                  loading={loading}
                  size="large"
                  type="primary"
                  htmlType="submit"
                >
                  Log In
                </Button>
                <p>
                  Don’t have an account?
                  <Link to="/create-account">Sign Up</Link>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </FormWrapper>
    </AuthSection>
  );
};

export default Login;
