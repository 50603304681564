import React, { useState } from "react";
import { Modal, Button } from "antd";
import { useUserContext } from "context/user";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AdminServices from "config/services/AdminServices";
import useToggle from "hooks/useToggle";
import EditEscalatorModal from "./editEscalatorModal";

const ViewEscalatorModal = ({ visible, closeModal, currEscalator }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { refetchEscalatorsData } = useUserContext();
  const [isEditEscalatorModalOpen, toggleEditEscalatorModal] = useToggle();

  const resetUserPassword = async () => {
    try {
      setIsLoading(true);
      const res = await AdminServices.adminResetUserPassword({
        username: currEscalator.username,
      });

      if (res.message === "success") {
        Modal.success({
          title: "User password reset successful!",
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleResetEscalatorModal = () => {
    Modal.confirm({
      title: "Are You Sure?",
      content: "This action is irreversible.",
      onOk: async () => {
        await resetUserPassword();
      },
      onCancel: () => {},
    });
  };

  const handleDeleteEscalatorModal = () => {
    Modal.confirm({
      title: "Are You Sure?",
      content: "This action is irreversible.",
      onOk: async () => {
        await deleteEscalator(currEscalator.id);
      },
      onCancel: () => {},
    });
  };

  const deleteEscalator = async (id) => {
    try {
      await AdminServices.deleteUser(id);

      Modal.success({
        title: "Escalator deleted successfully!",
      });

      refetchEscalatorsData();
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        title={<strong>Escalator Details</strong>}
        visible={visible}
        onCancel={closeModal}
        footer={[
          <Button
            key="reset-password"
            loading={isLoading}
            onClick={handleResetEscalatorModal}
            danger
          >
            Reset Password
          </Button>,
          <Button
            key="edit"
            icon={<EditOutlined />}
            onClick={toggleEditEscalatorModal}
          >
            Edit
          </Button>,
          <Button
            key="delete"
            icon={<DeleteOutlined />}
            onClick={handleDeleteEscalatorModal}
            danger
          >
            Delete
          </Button>,
        ]}
        destroyOnClose
      >
        <div>
          <p>
            <strong>Name: </strong>
            {`${currEscalator.first_name} ${currEscalator.last_name}`}
          </p>

          <p>
            <strong>Username: </strong>
            {currEscalator.username}
          </p>

          <p>
            <strong>Email: </strong>
            {currEscalator.email}
          </p>

          <p>
            <strong>Phone: </strong>
            {currEscalator.phone}
          </p>

          <p>
            <strong>Address: </strong>
            {currEscalator.address}
          </p>

          <p>
            <strong>Agency: </strong>
            {currEscalator.agency}
          </p>

          <p>
            <strong>Gender: </strong>
            {currEscalator.gender}
          </p>
        </div>
      </Modal>

      {isEditEscalatorModalOpen ? (
        <EditEscalatorModal
          currEscalator={currEscalator}
          visible={isEditEscalatorModalOpen}
          closeModal={toggleEditEscalatorModal}
          refetchEscalatorsData={refetchEscalatorsData}
        />
      ) : null}
    </>
  );
};

export default ViewEscalatorModal;
