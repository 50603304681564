import React, { Fragment, useState } from "react";
import { emergencyCategories } from "./emergencies";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import InputStyled from "components/inputStyled";
import MessageServices from "config/services/MessageServices";
import { useEscalatorContext } from "context/escalators";
import { useAuthContext } from "context/AuthContext";
import { useIssuesContext } from "context/issues";
import useAsync from "hooks/useAsync";
import IssueServices from "config/services/IssuesServices";

const FormItem = Form.Item;
const { Option } = Select;

const lgas = [
  "Agege",
  "Ajeromi-Ifelodun",
  "Alimosho",
  "Amuwo-Odofin",
  "Badagry",
  "Apapa",
  "Epe",
  "Eti Osa",
  "Ibeju-Lekki",
  "Ifako-Ijaiye",
  "Ikeja",
  "Ikorodu",
  "Kosofe",
  "Lagos Island",
  "Mushin",
  "Lagos Mainland",
  "Ojo",
  "Oshodi-Isolo",
  "Shomolu",
  "Surulere",
];

const AddCaseModal = ({ visible, closeModal, refetchData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responses, setResponses] = useState([]);
  const [questionsList, setQuestionsList] = useState([]);

  const [selectedEmergency, setSelectedEmergency] = useState({});

  const { loading: isEmergencyCodesLoading, data: emergency_codes } = useAsync(
    IssueServices.getEmergencyCodes
  );

  const {
    state: { adminInfo },
  } = useAuthContext();
  const { data: agencies } = useEscalatorContext();

  const { data: issues } = useIssuesContext();

  const [form] = Form.useForm();

  const handleSelectIssue = (val) => {
    const selectedIssue = issues.find((issue) => issue.id === val);
    setQuestionsList(selectedIssue?.question_list);
  };

  const handleSelectEmergency = (val, option) => {
    const index = option["data-index"];

    const current = emergencyCategories[index];

    setSelectedEmergency(current);
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      let responses = [];

      for (const key in values) {
        if (key.startsWith("question_")) {
          const [, questionId] = key.split("question_");
          responses.push({
            question: questionId,
            answer: values[key],
          });
        }
      }

      const payload = {
        ...values,
        agent: adminInfo.id,
        provider: "call",
        responses,
      };

      console.log(payload);

      setIsLoading(true);

      const res = await MessageServices.addMessage(payload);

      console.log(res);

      if (res.message === "success") {
        Modal.success({
          title: "Message added successfully",
          onOk: async () => {
            // await refetchSectors();
            await refetchData();
            closeModal();
          },
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(error);
    }
  };

  return (
    <Modal
      width={600}
      title={<strong>Add New Case</strong>}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          loading={isLoading}
          key="create"
          type="primary"
          onClick={handleSubmit}
        >
          Ok
        </Button>,
      ]}
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <Col lg={24}>
          <FormItem
            label="Name of Reporter"
            name="name"
            rules={[
              {
                message: "Please enter your name!",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Name of Reporter" />
          </FormItem>
        </Col>

        <Col lg={24}>
          <FormItem
            label="Phone Number"
            name="phone"
            rules={[
              {
                message: "Please input your phone number!",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Phone Number" />
          </FormItem>
        </Col>

        <FormItem
          label="Address"
          name="address"
          rules={[
            {
              message: "Please input your address!",
              required: true,
            },
          ]}
        >
          <InputStyled placeholder="Address" />
        </FormItem>

        <FormItem
          label="Nearest Bus-Stop"
          name="nearest_busstop"
          rules={[
            {
              message: "Please fill this fields!",
              required: true,
            },
          ]}
        >
          <InputStyled placeholder="Nearest Bus-Stop" />
        </FormItem>

        <FormItem
          label="Local Government Area"
          name="local_gov"
          rules={[
            {
              message: "Please select local governemnt!",
              required: true,
            },
          ]}
        >
          <Select
            size="large"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {lgas.map((lga) => (
              <Option key={lga} value={lga}>
                {lga}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem
          label="What's your nearest landmark?"
          name="landmark"
          rules={[
            {
              message: "Please input your nearest landmark",
              required: true,
            },
          ]}
        >
          <InputStyled placeholder="Nearest Landmark" />
        </FormItem>

        <FormItem
          label="Traffic Situation"
          name="traffic_situation"
          rules={[
            {
              message: "Please fill this fields!",
              required: true,
            },
          ]}
        >
          {/* <InputStyled placeholder="Traffic Situation" /> */}
          <Select size="large" placeholder="Traffic Situation">
            <option value="Heavy Traffic">Heavy traffic</option>
            <option value="Moving Traffic">Moving traffic</option>
            <option value="No Traffic">No traffic</option>
          </Select>
        </FormItem>

        <FormItem
          label="Select Incident"
          name="issues"
          rules={[
            {
              message: "Please select an incident",
              required: true,
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Select Incident"
            onChange={handleSelectIssue}
          >
            {issues.map((issue) => (
              <Option key={issue.id} value={issue.id}>
                {issue.name}
              </Option>
            ))}
          </Select>
        </FormItem>

        {questionsList.length > 0 ? <h3>Responses</h3> : null}

        {questionsList.map((question, idx) => {
          if (question.is_image) return null;

          return (
            <Fragment>
              <FormItem
                label={question.question}
                name={`question_${question.id}`}
                rules={[
                  {
                    message: "Please enter an answer",
                    required: true,
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="Enter Answer" />
              </FormItem>
            </Fragment>
          );
        })}

        {/* <FormItem
          label="Agency"
          name="agencies"
          rules={[
            {
              message: "Please select an escalator",
              required: true,
            },
          ]}
        >
          <Select mode="multiple" placeholder="Select Agency" size="large">
            {agencies.map((d) => (
              <Option key={d.id} value={d.id}>
                {d.acronym}
              </Option>
            ))}
          </Select>
        </FormItem> */}

        {/* <FormItem
          label="Emergency Code"
          name="emergency_code"
          rules={[
            {
              message: "select an emergency code",
              required: true,
            },
          ]}
        >
          <Select
            loading={isEmergencyCodesLoading}
            mode="multiple"
            size="large"
            placeholder="Select Emergency Code"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {emergency_codes?.data?.map((d) => (
              <Option key={d.id} value={d.id}>
                {d.code}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem label="Emergency Category" name="category" required>
          <Select size="large" placeholder="Select Category">
            {["emergency", "non_emergency", "hoax", "nuisance"].map((issue) => (
              <Option className="capitalize" key={issue} value={issue}>
                {issue.split("_").join("-")}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem
          label="Agent Note"
          name="agent_note"
          rules={[
            {
              message: "Please input your case description!",
              required: true,
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder="Agent Note" />
        </FormItem> */}
      </Form>
    </Modal>
  );
};

export default AddCaseModal;
