import styled from "styled-components";

const OptionsMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const TableWrapper = styled.div`
  margin: 2rem 0;
`;

const MessageItem = styled.div`
  border: 0.2px solid #c4c4c4;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .msg-header {
    margin-bottom: 0;

    strong {
      margin-right: 1.5rem;
    }
  }

  .ant-btn-link {
    color: #3383fd;
  }
`;

export { OptionsMenu, MessageItem, TableWrapper };
