import React, { useState } from "react";
import { Button, Form, message, Modal, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import MessageServices from "config/services/MessageServices";

const FormItem = Form.Item;
const { Option } = Select;

const ArchiveMessageModal = ({
  isOpen,
  closeModal,
  closeParentModal,
  currMessage,
  refetchPendingMessages,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const handleArchiveMessage = async () => {
    try {
      const values = await form.validateFields();

      setIsLoading(true);

      const response = await MessageServices.archiveMessage(currMessage.id, {
        ...values,
      });

      setIsLoading(false);

      if (response.message === "successful") {
        message.success("Message has been archived successfully");
        closeModal();
        closeParentModal();
        refetchPendingMessages();
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Are you sure you want to archive this message?"
      visible={isOpen}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          loading={isLoading}
          key="create"
          type="primary"
          onClick={handleArchiveMessage}
        >
          Ok
        </Button>,
      ]}
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <FormItem
          label="Reason for archive"
          name="archive_reason"
          rules={[
            {
              message: "Please enter your reason for archive",
              required: true,
            },
          ]}
        >
          <TextArea rows={6} placeholder="Reason for archive" />
        </FormItem>

        <FormItem label="Emergency Category" name="category" required>
          <Select size="large" placeholder="Select Category">
            {["emergency", "non_emergency", "hoax"].map((issue) => (
              <Option className="capitalize" key={issue} value={issue}>
                {issue.split("_").join("-")}
              </Option>
            ))}
          </Select>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default ArchiveMessageModal;
