import React, { createContext, useContext, useState } from "react";
import useAsync from "hooks/useAsync";
import MessageServices from "config/services/MessageServices";
import { useAuthContext } from "context/AuthContext";

const MessageContext = createContext();

function makePromise() {
  return new Promise((resolve, reject) => resolve([]));
}

const MessageProvider = ({ children }) => {
  const [filteredPendingMessages, setFilteredPendingMessages] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const {
    state: { adminInfo },
  } = useAuthContext();

  const {
    data,
    loading,
    refetchData,
    setData: setPendingMessages,
  } = useAsync(
    adminInfo.role === "agent"
      ? MessageServices.getPendingMessages
      : MessageServices.getEscalatedMessages
  );

  const {
    data: { data: allMessages },
    loading: isAllMessagesLoading,
    refetchData: refetchAllMessages,
  } = useAsync(
    adminInfo.role === ("admin" || "agents")
      ? MessageServices.getAllMessages
      : makePromise
  );

  const fetchFilteredPendingMessages = async (year) => {
    if (!year) return;

    try {
      setIsLoading(true);
      const { message, data } =
        await MessageServices.getFilteredPendingMessages(year);

      setIsLoading(false);

      if (message === "success") {
        setPendingMessages({ data });
      }
    } catch (error) {
      setIsLoading(false);

      console.log(error);
    }
  };

  return (
    <MessageContext.Provider
      value={{
        data,
        allMessages,
        filteredPendingMessages,
        loading: isLoading || loading,
        isAllMessagesLoading,
        refetchAllMessages,
        fetchFilteredPendingMessages,
        refetchData,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export function useMessageContext() {
  const context = useContext(MessageContext);

  if (context === undefined) {
    throw new Error(
      "useMessageContext should be used within an MessageProvider"
    );
  }

  const {
    data,
    loading,
    refetchData,
    isAllMessagesLoading,
    allMessages,
    filteredPendingMessages,
    refetchAllMessages,
    fetchFilteredPendingMessages,
  } = context;

  return {
    data,
    loading,
    refetchData,
    isAllMessagesLoading,
    allMessages,
    filteredPendingMessages,
    refetchAllMessages,
    fetchFilteredPendingMessages,
  };
}

export default MessageProvider;
