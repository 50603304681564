import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import CardWrapper from "components/cardWrapper";
import useAsync from "hooks/useAsync";
import AdminServices from "config/services/AdminServices";
import Spinner from "components/spinner";
import AgencyCasesTable from "./agencyCasesTable";
import EmergencyEscalationReports from "./emergencyEscalationReports";
import IncidentChartsByLGA from "./incidentChartsByLGA";
import LagosLogo from "../../../asset/img/lagos_logo.png";
import axios from "axios";
import requests from "config/services/httpService";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Flex } from "views/agents/messageDetailModal/style";
import html2pdf from "html2pdf.js/dist/html2pdf.min";

const months = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export const Header = ({ children }) => (
  <h2 style={{ textAlign: "center", marginBottom: "3rem" }}>{children}</h2>
);

const MonthlyReports = () => {
  const location = useLocation();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  //   const { data, loading } = useAsync(() =>
  //     AdminServices.getMonthlyReports(payload)
  //   );

  const fetchMonthlyReports = (payload) => {
    setLoading(true);
    AdminServices.getMonthlyReports(payload)
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (location?.search) {
      const date = location.search.split("?date=");
      const [year, month] = date[1].split("-");

      //   setPayload({ month: Number(month), year: Number(year) });

      fetchMonthlyReports({ month: Number(month), year: Number(year) });
    } else {
      fetchMonthlyReports();
    }
  }, [location.search]);

  const agencyCases = Object.keys(data).length
    ? Object.keys(data?.agency_cases).map((key) => {
        return {
          agency: key,
          data: {
            ...data?.agency_cases[key],
          },
        };
      })
    : [];

  const emergencyEscalationReports = Object.keys(data).length
    ? Object.keys(data?.cases_by_lga).map((key) => ({
        lga: key,
        emergency_cases: data?.cases_by_lga[key],
      }))
    : [];

  const issuesPerLGA = Object.keys(data).length
    ? Object.keys(data?.issue_per_lga).map((key) => ({
        issue: key,
        lga_data: data?.issue_per_lga[key],
      }))
    : [];

  const { emergency, non_emergency, hoax } = data?.category || {
    emergency: 0,
    non_emergency: 0,
    hoax: 0,
  };

  const pdfJSX = () => {
    return (
      <>
        <h1>JSX to PDF Convert Example</h1>
        <h2>Hello React</h2>
      </>
    );
  };

  const printHandler = () => {
    // const printElement = pdfJSX();

    const printElement = document.getElementById("report");

    html2pdf()
      .set({
        pagebreak: {
          mode: "avoid-all",
          // before: "#page2el"
        },
      })
      .from(printElement)
      .save();
  };

  const totalCases = emergency + non_emergency + hoax;
  const month = months[new Date().getMonth()];
  const year = new Date().getFullYear();

  if (loading) {
    return (
      <section>
        <CardWrapper>
          <Spinner />
        </CardWrapper>
      </section>
    );
  }

  return (
    <section>
      <Flex style={{ justifyContent: "space-between" }}>
        <Link to="/admin/d/reports">
          <Button icon={<ArrowLeftOutlined />}>Back</Button>
        </Link>

        {/* <Button type="primary" onClick={printHandler}>
          Download PDF
        </Button> */}
      </Flex>
      <CardWrapper id="report">
        <center>
          <div style={{ width: 150 }}>
            <img
              src={LagosLogo}
              alt="Lagos Logo"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </center>
        <h1 style={{ textAlign: "center", fontSize: "30px" }}>
          Emergency Response Centre Report for {month} {year}
        </h1>
        <div style={{ fontSize: "16px" }}>
          <Header>EXECUTIVE SUMMARY</Header>

          <p>
            {month} {year} recorded a total of:{" "}
          </p>

          <ul>
            <li>{data?.total_calls} Offered Call(s).</li>
            <li>{data?.total_whatsapp} Whatsapp Call(s).</li>
            <li>
              {totalCases} Cases were escalated to the Lagos State Emergency
              Agencies ({emergency} were Emergency cases while {non_emergency}{" "}
              were Non-Emergency cases)
            </li>
            <li>{hoax} of the reported cases were Hoax Cases.</li>
          </ul>
        </div>

        <AgencyCasesTable agencyCases={agencyCases} />
        <EmergencyEscalationReports
          emergencyEscalationReports={emergencyEscalationReports}
        />
        <IncidentChartsByLGA issuesPerLGA={issuesPerLGA} />
      </CardWrapper>
    </section>
  );
};

export default MonthlyReports;
