import React from "react";
import NavItems from "./navItems";
import { ReactComponent as Logo } from "asset/svg/crm_logo.svg";
import { HeaderStyled, LayoutStyled } from "./layoutStyles";

const AgentsLayout = ({ children }) => {
  return (
    <LayoutStyled>
      <HeaderStyled className="header">
        <div className="wrapper">
          <div className="logo-wrapper">
            {/* <Logo /> */}

            <div className="logo-wrapper">
              {/* <img src={LagosLogo} alt="Logo" /> */}
              <Logo />
            </div>
          </div>

          <NavItems />
        </div>
      </HeaderStyled>

      <main>{children}</main>
    </LayoutStyled>
  );
};

export default AgentsLayout;
