import React, { useState } from "react";
import { ReactComponent as SearchIcon } from "../../asset/svg/search.svg";
import { SearchWrapper } from "./searchStyles";

const SearchBar = ({ handleSearch }) => {
  const [value, setValue] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    handleSearch(value);
  };

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit}>
        <label>
          <input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Search"
          />
        </label>
        <button type="submit" className="search-btn">
          <SearchIcon />
        </button>
      </form>
    </SearchWrapper>
  );
};

export default SearchBar;
