import requests from "./httpService";

const EscalatorServices = {
  getEscalators() {
    return requests.get(`/agencies/`);
  },
  getSingleEscalator(id) {
    return requests.get(`/agencies/${id}`);
  },
  createEscalator(body) {
    return requests.post("/agencies/", body);
  },
  getEscalatedMessages() {
    return requests.get(`/agencies/escalated/`);
  },
};

export default EscalatorServices;
