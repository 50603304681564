import React, { useEffect } from "react";
import { notification } from "antd";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "views/login";
import Admin from "views/admin";
import Agents from "views/agents";
import NotFound from "components/404";
import AuthRoute from "utils/authRoute";
import CreateAccount from "views/createAccount";
import Escalators from "views/escalators";
import ForgotPassword from "views/forgotPassword";
import ResetPassword from "views/resetPassword";
import { useAuthContext } from "context/AuthContext";
import { getFirebaseToken, onMessage, messaging } from "./firebase";

// Styles
import "./App.less";
import AdminServices from "config/services/AdminServices";

function App() {
  const {
    state: { adminInfo },
    setFirebaseKey,
  } = useAuthContext();

  const sendTokenToServer = async (token) => {
    try {
      const response = await AdminServices.changeFirebaseKey({
        firebase_key: token,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (adminInfo?.access) {
      console.log({ adminInfo });

      getFirebaseToken(setFirebaseKey)
        .then((firebaseToken) => {
          console.log({ firebaseToken });
          // setFirebaseKey(firebaseToken);
          sendTokenToServer(firebaseToken);
        })
        .catch((err) => console.log(err));
    }
  }, [adminInfo]);

  // onMessage(messaging, (payload) => {
  //   console.wlog("Foreground Message received. ", payload);

  //   notification.info({
  //     description: payload.notification.body,
  //     message: payload.notification.title,
  //   });

  //   console.log(payload);
  // });

  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/create-account" component={CreateAccount} />
        <Route path="/forgot-password/:token" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <AuthRoute path="/admin" component={Admin} />
        <AuthRoute path="/agents" component={Agents} />
        <AuthRoute path="/escalators" component={Escalators} />
        <Route exact path="/" component={Login} />
        <Route path="/404" component={NotFound} />

        <Route path="/*" component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
