import React, { useState, useEffect } from "react";
import { Table, Button, message } from "antd";
import CardWrapper from "components/cardWrapper";
import { useUserContext } from "context/user";
import SearchBar from "components/searchbar";
import { TableWrapper } from "../agents/agentStyles";
import useToggle from "hooks/useToggle";
import AddEscalatorModal from "./modals/addEscalatorModal";
import { useEscalatorContext } from "context/escalators";
import ViewEscalatorModal from "./modals/viewEscalatorModal";

const Escalators = () => {
  const [currEscalator, setCurrEscalator] = useState();
  const [filteredEscalators, setFilteredEscalators] = useState([]);

  const { loading, escalators, refetchEscalatorsData } = useUserContext();
  const { data: agencies } = useEscalatorContext();

  const [isAddEscalatorModalOpen, toggleAddEscalatorModal] = useToggle();
  const [isViewEscalatorModalOpen, toggleViewEscalatorModal] = useToggle();

  useEffect(() => {
    if (escalators) {
      setFilteredEscalators(escalators);
    }
  }, [escalators]);

  const columns = [
    {
      title: "S/N",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Agency",
      dataIndex: "agency",
      key: "agency",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (record) => <span className="capitalize">{record}</span>,
    },
    {
      title: "Actions",
      key: "action",
      align: "centre",
      render: (record) => (
        <Button
          type="link"
          onClick={() => {
            setCurrEscalator(record);
            toggleViewEscalatorModal();
          }}
        >
          view
        </Button>
      ),
      // render: (record, key) => {
      //   return (
      //     <Space size="middle">
      //       <Button
      //         onClick={() => {
      //           setCurrEscalator(record);
      //           toggleEditEscalatorModal();
      //         }}
      //       >
      //         <EditOutlined />
      //       </Button>

      //       <Button
      //         onClick={() => handleDeleteEscalatorModal(record.id)}
      //         danger
      //       >
      //         <DeleteOutlined />
      //       </Button>
      //     </Space>
      //   );
      // },
    },
  ];

  const handleSearch = (value) => {
    const originalData = [
      ...escalators.map((d, i) => ({
        ...d,
        key: i + 1,
      })),
    ];

    if (!value) setFilteredEscalators(originalData);

    const result = originalData.filter((d) => {
      return Object.keys(d).some((key) =>
        String(d[key]).toLowerCase().includes(value.toLowerCase())
      );
    });

    if (result.length === 0) {
      message.warning("No Data Found!", 5);
    }

    setFilteredEscalators(result);
  };

  return (
    <section>
      <CardWrapper
        title={`All Escalators (${filteredEscalators.length})`}
        extra={
          <Button onClick={toggleAddEscalatorModal} type="primary">
            Add New Escalator
          </Button>
        }
      >
        <SearchBar handleSearch={handleSearch} />

        <TableWrapper>
          <Table
            loading={loading}
            className="table-responsive"
            columns={columns}
            dataSource={filteredEscalators?.map((d, idx) => ({
              ...d,
              rank: idx + 1,
              name: `${d.first_name} ${d.last_name}`,
              username: d.username,
              email: d.email,
              phone: d.phone,
              address: d.address,
              gender: d.gender,
              agency: agencies?.find((item) => d.agency === item.id)?.acronym,
            }))}
          />
        </TableWrapper>
      </CardWrapper>

      {isAddEscalatorModalOpen ? (
        <AddEscalatorModal
          visible={isAddEscalatorModalOpen}
          closeModal={toggleAddEscalatorModal}
          refetchEscalatorsData={refetchEscalatorsData}
        />
      ) : null}

      {isViewEscalatorModalOpen ? (
        <ViewEscalatorModal
          currEscalator={currEscalator}
          visible={isViewEscalatorModalOpen}
          closeModal={toggleViewEscalatorModal}
        />
      ) : null}
    </section>
  );
};

export default Escalators;
