import React, { createContext, useContext } from "react";
import useAsync from "hooks/useAsync";
import AdminServices from "config/services/AdminServices";
import { useAuthContext } from "context/AuthContext";
import AgencyServices from "config/services/AgencyServices";

const UserContext = createContext();

function makePromise() {
  return new Promise((resolve, reject) => resolve([]));
}

const UserProvider = ({ children }) => {
  const {
    state: { adminInfo },
  } = useAuthContext();

  const {
    data: { data: agents },
    loading: isAgentsLoading,
    refetchData: refetchAgentsData,
  } = useAsync(
    adminInfo.role === "admin" ? AdminServices.getAgents : makePromise
  );

  const {
    data: { data: escalators },
    loading: isEscalatorsLoading,
    refetchData: refetchEscalatorsData,
  } = useAsync(
    adminInfo.role === "admin" ? AdminServices.getEscalators : makePromise
  );

  const {
    data: { data: firstResponders },
    loading: isRespondersLoading,
    refetchData: refetchFirstRespondersData,
  } = useAsync(AdminServices.getFirstResponders);

  const {
    data: { data: agencies },
    loading: isAgenciesLoading,
    refetchData: refetchAgenciesData,
  } = useAsync(AgencyServices.getAgencies);

  return (
    <UserContext.Provider
      value={{
        agents,
        firstResponders,
        escalators,
        agencies,
        loading:
          isAgentsLoading ||
          isEscalatorsLoading ||
          isRespondersLoading ||
          isAgenciesLoading,
        refetchEscalatorsData,
        refetchAgentsData,
        refetchFirstRespondersData,
        refetchAgenciesData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export function useUserContext() {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("useUserContext should be used within an UserProvider");
  }

  const {
    agents,
    agencies,
    escalators,
    firstResponders,
    loading,
    refetchFirstRespondersData,
    refetchEscalatorsData,
    refetchAgentsData,
    refetchAgenciesData,
  } = context;

  return {
    agents,
    agencies,
    firstResponders,
    escalators,
    loading,
    refetchFirstRespondersData,
    refetchEscalatorsData,
    refetchAgentsData,
    refetchAgenciesData,
  };
}

export default UserProvider;
