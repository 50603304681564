import React, { useState } from "react";
import { Modal, Button } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import useToggle from "hooks/useToggle";
import EditAgentModal from "./editAgentModal";
import AdminServices from "config/services/AdminServices";

const ViewAgentModal = ({
  visible,
  closeModal,
  currentAgent,
  refetchAgentsData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditAgentModalOpen, toggleEditAgentModal] = useToggle();

  const resetUserPassword = async () => {
    try {
      setIsLoading(true);
      const res = await AdminServices.adminResetUserPassword({
        username: currentAgent.username,
      });

      if (res.message === "success") {
        Modal.success({
          title: "User password reset successful!",
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleResetAgentModal = () => {
    Modal.confirm({
      title: "Are You Sure?",
      content: "This action is irreversible.",
      onOk: async () => {
        await resetUserPassword();
      },
      onCancel: () => {},
    });
  };

  const handleDeleteAgentModal = () => {
    Modal.confirm({
      title: "Are You Sure?",
      content: "This action is irreversible.",
      onOk: async () => {
        await deleteAgent(currentAgent.id);
      },
      onCancel: () => {},
    });
  };

  const deleteAgent = async (id) => {
    try {
      await AdminServices.deleteUser(id);

      Modal.success({
        title: "Agent deleted successfully!",
      });
      refetchAgentsData();
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        title={<strong>Agent Details</strong>}
        visible={visible}
        onCancel={closeModal}
        footer={[
          <Button
            key="reset-password"
            loading={isLoading}
            onClick={handleResetAgentModal}
            danger
          >
            Reset Password
          </Button>,
          <Button
            key="edit"
            icon={<EditOutlined />}
            onClick={toggleEditAgentModal}
          >
            Edit
          </Button>,
          <Button
            key="delete"
            icon={<DeleteOutlined />}
            onClick={handleDeleteAgentModal}
            danger
          >
            Delete
          </Button>,
        ]}
        destroyOnClose
      >
        <div>
          <p>
            <strong>Name: </strong>
            {`${currentAgent.first_name} ${currentAgent.last_name}`}
          </p>

          <p>
            <strong>Username: </strong>
            {currentAgent.username}
          </p>

          <p>
            <strong>Email: </strong>
            {currentAgent.email}
          </p>

          <p>
            <strong>Phone: </strong>
            {currentAgent.phone}
          </p>

          <p>
            <strong>Address: </strong>
            {currentAgent.address}
          </p>

          <p>
            <strong>Gender: </strong>
            {currentAgent.gender}
          </p>
        </div>
      </Modal>

      {isEditAgentModalOpen ? (
        <EditAgentModal
          currAgent={currentAgent}
          visible={isEditAgentModalOpen}
          closeModal={toggleEditAgentModal}
        />
      ) : null}
    </>
  );
};

export default ViewAgentModal;
