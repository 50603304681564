import React, { useEffect, useState } from "react";
import { Table, Button, message } from "antd";
import CardWrapper from "components/cardWrapper";
import SearchBar from "components/searchbar";
import { TableWrapper } from "./agentStyles";
import useToggle from "hooks/useToggle";
import { useUserContext } from "context/user";
import ViewAgentModal from "./modals/viewAgentModal";
import AddAgentModal from "./modals/addAgentModal";

const Agents = () => {
  const [currAgent, setCurrAgent] = useState();
  const [filteredAgents, setFilteredAgents] = useState([]);
  const { loading, agents, refetchAgentsData } = useUserContext();

  const [isAddAgentModalOpen, toggleAddAgentModal] = useToggle();
  const [isViewAgentModalOpen, toggleViewAgentModal] = useToggle();

  useEffect(() => {
    if (agents) {
      setFilteredAgents(agents);
    }
  }, [agents]);

  console.log("Agents :", agents);

  const columns = [
    {
      title: "S/N",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (record) => <span className="capitalize">{record}</span>,
    },
    {
      title: "Actions",
      key: "action",
      align: "center",
      render: (record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setCurrAgent(record);
              toggleViewAgentModal();
            }}
          >
            view
          </Button>
        );
      },
      // render: (record) => {
      //   return (
      //     <Space size="middle">
      //       <Button
      //         type=""
      //         onClick={() => {
      //           setCurrAgent(record);
      //           toggleEditAgentModal();
      //         }}
      //       >
      //         <EditOutlined />
      //       </Button>

      //       <Button onClick={() => handleDeleteAgentModal(record.id)} danger>
      //         <DeleteOutlined />
      //       </Button>
      //     </Space>
      //   );
      // },
    },
  ];

  const handleSearch = (value) => {
    const originalData = [
      ...agents?.map((d, i) => ({
        ...d,
        key: i + 1,
      })),
    ];

    if (!value) setFilteredAgents(originalData);

    const result = originalData.filter((d) => {
      return Object.keys(d).some((key) =>
        String(d[key]).toLowerCase().includes(value.toLowerCase())
      );
    });

    if (result.length === 0) {
      message.warning("No Data Found!", 5);
    }

    setFilteredAgents(result);
  };

  return (
    <section>
      <CardWrapper
        title={`All Agents (${filteredAgents.length})`}
        extra={
          <Button onClick={toggleAddAgentModal} type="primary">
            Add New Agent
          </Button>
        }
      >
        <SearchBar handleSearch={handleSearch} />

        <TableWrapper>
          <Table
            loading={loading}
            className="table-responsive"
            columns={columns}
            dataSource={filteredAgents?.map((d, idx) => ({
              rank: idx + 1,
              name: `${d.first_name} ${d.last_name}`,
              username: d.username,
              email: d.email,
              phone: d.phone,
              address: d.address,
              gender: d.gender,
              ...d,
            }))}
          />
        </TableWrapper>
      </CardWrapper>

      {isAddAgentModalOpen ? (
        <AddAgentModal
          visible={isAddAgentModalOpen}
          closeModal={toggleAddAgentModal}
        />
      ) : null}

      {isViewAgentModalOpen ? (
        <ViewAgentModal
          visible={isViewAgentModalOpen}
          closeModal={toggleViewAgentModal}
          currentAgent={currAgent}
          refetchAgentsData={refetchAgentsData}
        />
      ) : null}
    </section>
  );
};

export default Agents;
