import React, { useRef, useCallback } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import {
  AvatarButton,
  DropdownItemStyled,
  NavItemsStyled,
} from "./layoutStyles";
import { ReactComponent as Avatar } from "asset/svg/avatar.svg";
import useToggle from "hooks/useToggle";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useAuthContext } from "context/AuthContext";

const DropdownItem = ({
  isOpen,
  adminInfo,
  handleLogOut,
  toggleDropdown,
  closeDropdown,
}) => {
  const ref = useRef();
  const handler = useCallback(() => closeDropdown(), [closeDropdown]);

  useOnClickOutside(ref, handler);

  const { url } = useRouteMatch();

  return (
    <DropdownItemStyled ref={ref} ml={0}>
      <AvatarButton onClick={toggleDropdown}>
        <span className="css-nxs594">
          <Avatar />
        </span>

        <span className="text">{`${adminInfo?.first_name} ${adminInfo?.last_name}`}</span>
        <DownOutlined />
      </AvatarButton>

      {isOpen && (
        <ul className="content">
          <Link to={url + "/d/profile"}>
            <li>Profile</li>
          </Link>

          <Link to={url + "/d/change-password"}>
            <li>Change Password</li>
          </Link>

          <Link to={url + "/d/reset-password"}>
            <li>Reset Password</li>
          </Link>

          <li onClick={handleLogOut}>Log Out</li>
        </ul>
      )}
    </DropdownItemStyled>
  );
};

const NavItems = () => {
  const [isDropdownOpen, toggleDropdown, closeDropdown] = useToggle(false);
  const {
    state: { adminInfo },
    dispatch,
  } = useAuthContext();

  const handleLogOut = () => {
    dispatch({ type: "USER_LOGOUT" });
    localStorage.removeItem("adminInfo");
  };

  return (
    <NavItemsStyled>
      <DropdownItem
        adminInfo={adminInfo}
        isOpen={isDropdownOpen}
        handleLogOut={handleLogOut}
        toggleDropdown={toggleDropdown}
        closeDropdown={closeDropdown}
      />
    </NavItemsStyled>
  );
};

export default NavItems;
