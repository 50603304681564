import React from "react";
import { Col, Row } from "antd";
import BarChart from "./barchart";
import DoughnutChart from "./piechart";
import TotalReportedCases from "./reportedCases";
import EscalatedCases from "./escalatedCases";

const Charts = () => {
  return (
    <Row gutter={24}>
      {/* <Col xs={24} sm={24} md={24} lg={16}>
        <BarChart />
        <TotalReportedCases />
      </Col>

      <Col xs={24} sm={24} md={24} lg={8}>
        <DoughnutChart />
      </Col> */}

      <Col xs={24} sm={24}>
        <TotalReportedCases />
      </Col>

      <Col xs={24} sm={24}>
        <EscalatedCases />
      </Col>
    </Row>
  );
};

export default Charts;
