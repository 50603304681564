import React, { useState } from "react";
import { Button, Checkbox, Modal } from "antd";
import { useEscalatorContext } from "context/escalators";
import styled from "styled-components";
import MessageServices from "config/services/MessageServices";

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-top: 1px solid #dadada;

  .alert-details {
    display: flex;
    font-size: 16px;

    .number {
    }

    .content {
      margin-left: 1.5rem;

      p {
        font-weight: bold;
      }
    }
  }

  .one {
    color: #d96a1b;
    width: 170px;
  }

  .new {
    background-color: #c2dbff;
    text-transform: capitalize;
  }

  .pending {
    background-color: #ffe282;
    text-transform: capitalize;
  }

  .ongoing {
    background-color: #f6a19b;
    text-transform: capitalize;
  }

  .completed {
    background-color: #a1ff75;
    text-transform: capitalize;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;

  .checkbox-wrapper {
    margin-bottom: 1rem;
  }
`;

const Summary = styled.summary`
  list-style: none;
  cursor: pointer;
`;

const AlertItem = ({ message, handleToggleMessageDetail, idx }) => {
  const [loading, setLoading] = useState(false);
  const [selectedEscalators, setSelectedEscalators] = useState([]);
  const { data, refetchData } = useEscalatorContext();

  const handleMessageEscalation = async () => {
    try {
      setLoading(true);

      const payload = {
        agencies: selectedEscalators,
      };

      const response = await MessageServices.escalateMessage(
        message.id,
        payload
      );

      setLoading(false);

      console.log(response);

      if (response.status === 204) {
        Modal.success({
          title: "Emergency has been escalated successfully",
        });
      }
      refetchData();
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const handleSelectEscalator = (e, id) => {
    if (e.target.checked) {
      setSelectedEscalators([...selectedEscalators, id]);
    } else {
      setSelectedEscalators(selectedEscalators.filter((idx) => idx !== id));
    }
  };

  const getEmergencyName = (category) => {
    return category.toString() || category.split("_").join(" ");
  };

  return (
    // <details className="alert-item">
    <Summary>
      <Div>
        <div className="alert-details">
          <span className="number">{idx}.</span>

          <div className="content">
            <p>{message?.name}</p>
            {/* <span className="capitalize">{`${getEmergencyName(
              message.category
            )}, ${message.address}`}</span> */}

            <span>{`${message.issue}, ${message.address}`}</span>
          </div>
        </div>

        <Button
          onClick={() => handleToggleMessageDetail(message)}
          className={`one ${message.status}`}
        >
          View
        </Button>
      </Div>
    </Summary>

    // <Wrapper>
    //   <div className="checkbox-wrapper">
    //     {data?.map((d) => (
    //       <Checkbox
    //         key={d.id}
    //         checked={selectedEscalators.includes(d.id)}
    //         onChange={(e) => handleSelectEscalator(e, d.id)}
    //       >
    //         {d.acronym}
    //       </Checkbox>
    //     ))}
    //   </div>

    //   <Button
    //     loading={loading}
    //     onClick={handleMessageEscalation}
    //     type="primary"
    //   >
    //     Escalate
    //   </Button>
    // </Wrapper>
    // </details>
  );
};

export default AlertItem;
