import React, { useState, useEffect } from "react";
import { Table, Button, Space, Modal, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import CardWrapper from "components/cardWrapper";
import SearchBar from "components/searchbar";
import { TableWrapper } from "./agentStyles";
import useToggle from "hooks/useToggle";
import AddAgencyModal from "./modals/addAgencyModal";
import EditAgencyModal from "./modals/editAgencyModal";
import useAsync from "hooks/useAsync";
import AgencyServices from "config/services/AgencyServices";

const Agency = () => {
  const [currAgency, setCurrAgency] = useState();
  const [filteredAgencies, setFilteredAgencies] = useState([]);

  const {
    loading,
    refetchData: refetchAgencyData,
    data: { data: agencies },
  } = useAsync(AgencyServices.getAgencies);

  const [isAddAgencyModalOpen, toggleAddAgencyModal] = useToggle();
  const [isEditAgencyModalOpen, toggleEditAgencyModal] = useToggle();

  useEffect(() => {
    if (agencies) {
      setFilteredAgencies(agencies);
    }
  }, [agencies]);

  const handleDeleteAgencyModal = (agencyId) => {
    Modal.confirm({
      title: "Are You Sure?",
      onOk: async () => {
        await deleteAgency(agencyId);
      },
    });
  };

  const deleteAgency = async (agencyId) => {
    await AgencyServices.deleteAgency(agencyId);

    Modal.success({
      title: "Agency deleted successfully!",
    });

    refetchAgencyData();
  };

  const columns = [
    {
      title: "S/N",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Acronym",
      dataIndex: "acronym",
      key: "acronym",
    },
    {
      title: "Date Added",
      dataIndex: "date_added",
      key: "date_added",
    },
    {
      title: "Actions",
      key: "action",
      align: "center",

      render: (record, key) => {
        return (
          <Space size="middle">
            <Button
              type=""
              onClick={() => {
                setCurrAgency(record);
                toggleEditAgencyModal();
              }}
            >
              <EditOutlined />
            </Button>

            <Button onClick={() => handleDeleteAgencyModal(record.id)} danger>
              <DeleteOutlined />
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleSearch = (value) => {
    const originalData = [
      ...agencies.map((d, i) => ({
        ...d,
        key: i + 1,
      })),
    ];

    if (!value) setFilteredAgencies(originalData);

    const result = originalData.filter((d) => {
      return Object.keys(d).some((key) =>
        String(d[key]).toLowerCase().includes(value.toLowerCase())
      );
    });

    if (result.length === 0) {
      message.warning("No Data Found!", 5);
    }

    setFilteredAgencies(result);
  };

  return (
    <section>
      <CardWrapper
        title={`All Agencies (${filteredAgencies.length})`}
        extra={
          <Button onClick={toggleAddAgencyModal} type="primary">
            Add New Agency
          </Button>
        }
      >
        <SearchBar handleSearch={handleSearch} />

        <TableWrapper>
          <Table
            loading={loading}
            className="table-responsive"
            columns={columns}
            dataSource={filteredAgencies?.map((d, idx) => ({
              ...d,
              rank: idx + 1,
              name: d.name,
              acronym: d.acronym,
              date_added: new Date(d.date_added).toLocaleString(),
            }))}
          />
        </TableWrapper>
      </CardWrapper>

      {isAddAgencyModalOpen ? (
        <AddAgencyModal
          visible={isAddAgencyModalOpen}
          refetchAgencyData={refetchAgencyData}
          closeModal={toggleAddAgencyModal}
        />
      ) : null}

      {isEditAgencyModalOpen ? (
        <EditAgencyModal
          currAgency={currAgency}
          refetchAgencyData={refetchAgencyData}
          visible={isEditAgencyModalOpen}
          closeModal={toggleEditAgencyModal}
        />
      ) : null}
    </section>
  );
};

export default Agency;
