import React from "react";
import { useAuthContext } from "context/AuthContext";
import { Route, Redirect, useLocation } from "react-router-dom";

const AuthRoute = ({ component, path, ...rest }) => {
  const {
    state: { adminInfo },
  } = useAuthContext();

  const location = useLocation();

  if (!adminInfo && adminInfo?.role) {
    // console.log("Inside AuthRoute Accessed This...");

    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }

  if (path === "/admin" && adminInfo?.role !== "admin") {
    return <Redirect path="/404" />;
  }

  if (path === "/agents" && adminInfo?.role !== "agent") {
    return <Redirect path="/404" />;
  }

  if (path === "/escalators" && adminInfo?.role !== "escalator") {
    return <Redirect path="/404" />;
  }

  return <Route component={component} path={path} {...rest} />;
};

export default AuthRoute;
