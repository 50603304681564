import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, Input, Select } from "antd";
import { AuthSection, FormWrapper } from "../login/loginStyles";
import InputStyled from "components/inputStyled";

const FormItem = Form.Item;
const Option = Select.Option;

const CreateAccount = () => {
  return (
    <AuthSection>
      <div className="bg-wrapper"></div>

      <FormWrapper>
        <div className="max-width-wrapper">
          <div className="content">
            <h2 className="title">Create Account</h2>
            <p className="desc">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
              aliquam, suscipit ultricies amet ac tincidunt morbi. Nulla
              volutpat sit.
            </p>

            <Form layout="vertical">
              <FormItem
                label="First Name"
                name="firstname"
                rules={[
                  {
                    message: "Please input your First Name!",
                    required: true,
                  },
                ]}
              >
                <InputStyled placeholder="First Name" />
              </FormItem>

              <FormItem
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    message: "Please input your Last Name!",
                    required: true,
                  },
                ]}
              >
                <InputStyled placeholder="Last Name" />
              </FormItem>

              <FormItem
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not a valid E-mail!",
                  },
                  {
                    message: "Please input your Email!",
                    required: true,
                  },
                ]}
              >
                <InputStyled placeholder="Email Address" />
              </FormItem>

              <FormItem
                label="Phone Number"
                name="phone"
                rules={[
                  {
                    message: "Please input your phone number!",
                    required: true,
                  },
                ]}
              >
                <InputStyled placeholder="Phone Number" />
              </FormItem>

              <FormItem
                label="Password"
                name="password"
                rules={[
                  { message: "Please input your password", required: true },
                ]}
              >
                <Input.Password size="large" placeholder="Password" />
              </FormItem>

              <FormItem
                name="confirm"
                label="Confirm Password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </FormItem>

              <FormItem
                label="Gender"
                name="gender"
                rules={[
                  { message: "Please select your gender", required: true },
                ]}
              >
                <Select>
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                </Select>
              </FormItem>

              <div className="btn-submit-wrapper">
                <Button size="large" type="primary">
                  Create Account
                </Button>
                <p>
                  Already have an account?
                  <Link to="/login">Create Account</Link>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </FormWrapper>
    </AuthSection>
  );
};

export default CreateAccount;
