import React, { useState } from "react";
import { Button, Col, Form, Modal, Row, Select } from "antd";
import InputStyled from "components/inputStyled";
import CardWrapper from "components/cardWrapper";
import { AvatarWrapper, ImagegPlaceholder, UploadButton } from "./profileStyle";
import { useAuthContext } from "context/AuthContext";
import AdminServices from "config/services/AdminServices";

const FormItem = Form.Item;
const Option = Select.Option;

const Profile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { updateProfile } = useAuthContext();

  const [form] = Form.useForm();

  const {
    state: { adminInfo },
  } = useAuthContext();

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      setIsLoading(true);

      const res = await AdminServices.updateProfile(values);

      console.log(res);

      const { phone, email, address, gender, first_name, last_name } = res.data;

      if (res.message === "success") {
        Modal.success({
          title: "Profile updated successfully",
          onOk: async () => {
            updateProfile({
              phone,
              email,
              address,
              gender,
              first_name,
              last_name,
            });
          },
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(error);
    }
  };

  return (
    <section>
      <CardWrapper>
        <AvatarWrapper>
          <ImagegPlaceholder />

          <UploadButton>Choose Photo</UploadButton>
        </AvatarWrapper>

        <Form
          layout="vertical"
          onFinish={handleSubmit}
          form={form}
          initialValues={{
            ...adminInfo,
          }}
        >
          <Row gutter={[24, 24]}>
            <Col sm={24} md={16} lg={12}>
              <FormItem label="First Name" name="first_name">
                <InputStyled placeholder="First Name" />
              </FormItem>
            </Col>

            <Col sm={24} md={12} lg={12}>
              <FormItem label="Last Name" name="last_name">
                <InputStyled placeholder="Last Name" />
              </FormItem>
            </Col>

            <Col sm={24} md={12} lg={12}>
              <FormItem label="Email Address" name="email">
                <InputStyled placeholder="Email Address" />
              </FormItem>
            </Col>

            <Col sm={24} md={12} lg={12}>
              <FormItem label="Phone Number" name="phone">
                <InputStyled placeholder="Phone Number" />
              </FormItem>
            </Col>

            <Col span={24}>
              <FormItem label="Residential Address" name="address">
                <InputStyled placeholder="Residential address" />
              </FormItem>
            </Col>

            <Col span={24}>
              <FormItem label="Gender" name="gender">
                <Select size="large">
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>

          <Row justify="center">
            <Button
              loading={isLoading}
              htmlType="submit"
              size="large"
              type="primary"
            >
              Save Changes
            </Button>
          </Row>
        </Form>
      </CardWrapper>
    </section>
  );
};

export default Profile;
