import requests from "./httpService";

const MessageServices = {
  addMessage(body) {
    return requests.post(`/message/`, body);
  },
  getAllMessages(page = 1, limit = 10, filterCategory) {
    if (page === 1) {
      return requests.get(
        `/messages/all?limit=${limit}${
          filterCategory ? `&filterCategory=${filterCategory}` : ""
        }`
      );
    }
    return requests.get(
      `/messages/all?limit=${limit}&offset=${
        limit * (page - 1)
      }?filterCategory=${filterCategory}`
    );
  },
  assignMessage(body) {
    return requests.post("/messages/assign/", body);
  },
  getAssignedMessages() {
    return requests.get(`/messages/assigned/`);
  },
  addReport(assgined_case_id, body) {
    return requests.post(
      `/messages/assigned/${assgined_case_id}/add_report`,
      body
    );
  },
  getEscalatedMessages() {
    return requests.get(`/messages/escalated`);
  },
  getPendingMessages() {
    return requests.get(`/messages/pending`);
  },
  getFilteredPendingMessages(year) {
    return requests.get(`/messages/pending?filterDate=${year}`);
  },
  getSingleMessage(message_id) {
    return requests.get(`/messages/${message_id}/`);
  },
  updateMessage(message_id, body) {
    return requests.put(`/messages/${message_id}/`, body);
  },
  deleteMessage(message_id) {
    return requests.delete(`/messages/${message_id}/`);
  },
  escalateMessage(message_id, body) {
    return requests.post(`/messages/${message_id}/escalate/`, body);
  },
  archiveMessage(message_id, body) {
    return requests.post(`/messages/${message_id}/archive/`, body);
  },
  getReport(message_id) {
    return requests.get(`/messages/${message_id}/report`);
  },

  markMessageAsEmergency(message_id) {
    return requests.get(`/messages/${message_id}/mark_as_emergency/`);
  },

  getEscalatedCasesByAgency() {
    return requests.get(`/dashboard/escalated_cases_by_agency`);
  },

  getReportedCasesByIssues() {
    return requests.get(`/dashboard/reported_cases_by_issues`);
  },
};

export default MessageServices;
