import requests from "./httpService";

const AgencyServices = {
  getAgencies() {
    return requests.get(`/agencies/`);
  },
  getSingleAgency(id) {
    return requests.get(`/agencies/${id}`);
  },
  createAgency(body) {
    return requests.post("/agencies/", body);
  },
  updateAgency(id, body) {
    return requests.put(`/agencies/${id}`, body);
  },
  deleteAgency(id) {
    return requests.delete(`/agencies/${id}`);
  },
};

export default AgencyServices;
