import React from "react";
import DisplayBarChart from "./generateBarChart";

const IncidentChartsByLGA = ({ issuesPerLGA }) => {
  return issuesPerLGA.map((issuesPerLGA) => (
    <DisplayBarChart data={issuesPerLGA} />
  ));
};

export default IncidentChartsByLGA;
