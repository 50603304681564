import React, { useState, useEffect } from "react";
import { Button, Checkbox, Form, Input, message, Modal, Select } from "antd";
import { useEscalatorContext } from "context/escalators";
import MessageServices from "config/services/MessageServices";
import { useMessageContext } from "context/messages";
import { isValidURL } from "utils/helpers";
import { Flex, StyledForm, StyledModal } from "./style";
import useAsync from "hooks/useAsync";
import IssueServices from "config/services/IssuesServices";
import useToggle from "hooks/useToggle";
import ArchiveMessageModal from "../archiveMessage";

const FormItem = Form.Item;
const { Option } = Select;

const MessageDetailModal = ({ currMessage, visible, closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [isEmergency, setIsEmergency] = useState(false);
  const [selectedEscalators, setSelectedEscalators] = useState([]);

  const { loading: isEmergencyCodesLoading, data: emergency_codes } = useAsync(
    IssueServices.getEmergencyCodes
  );

  const [isArchiveMessageModalOpen, toggleArchiveMessageModal] =
    useToggle(false);
  const { data: agencies } = useEscalatorContext();
  const { refetchData: refetchPendingMessages } = useMessageContext();

  const [form] = Form.useForm();

  useEffect(() => {
    if (agencies.length > 0) {
      const defaultSelectedEscalators = agencies.find(
        (agency) => agency?.acronym === "LASEMA"
      );
      setSelectedEscalators([
        ...selectedEscalators,
        defaultSelectedEscalators?.id,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencies]);

  const makeRequest = async (payload) => {
    setLoading(true);

    const response = await MessageServices.escalateMessage(
      currMessage.id,
      payload
    );

    console.log(response);

    setLoading(false);

    if (response.message === "successful" || response.status === 201) {
      Modal.success({
        title: "Emergency has been escalated successfully",
        onOk: () => closeModal(),
      });
    } else {
      Modal.error({
        title: "An error occurred!",
      });

      setLoading(false);
    }
    refetchPendingMessages();
  };

  const handleMessageEscalation = async () => {
    try {
      const values = await form.validateFields();

      console.log(values);

      const payload = {
        ...values,
        agent_note: values.agent_note,
      };

      console.log(payload);

      // if (!isEmergency) {
      //   return Modal.confirm({
      //     title: "Are you sure it's not an emregency?",
      //     onCancel: () => {
      //       return;
      //     },
      //     onOk: async () => {
      //       await makeRequest(payload);
      //     },
      //   });
      // } else {
      //   return
      // }

      await makeRequest(payload);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const handleSelectEscalator = (e, id) => {
    if (e.target.checked) {
      setSelectedEscalators([...selectedEscalators, id]);
    } else {
      setSelectedEscalators(selectedEscalators.filter((idx) => idx !== id));
    }
  };

  const handleEmergency = (checked) => setIsEmergency(checked);

  return (
    <StyledModal
      width={800}
      visible={visible}
      onCancel={closeModal}
      title={null}
      footer={null}
      closable
    >
      <h2 className="header">Details</h2>

      <div className="message-content">
        <div className="first">
          <p className="title">
            <strong>Name of Reporter: </strong>
            <span>{currMessage.name}</span>
          </p>

          <p className="title">
            <strong>Emergency: </strong>
            <span className="capitalize">{currMessage.issue || "--"}</span>
          </p>

          <p className="title">
            <strong>Address: </strong>
            <span>{currMessage.address}</span>
          </p>

          <p className="title">
            <strong>Phone Number: </strong>
            <span>{currMessage.phone}</span>
          </p>

          <p className="title">
            <strong>LGA: </strong>
            <span>{currMessage.local_gov || "--"}</span>
          </p>

          <p className="title">
            <strong>Nearest Landmark: </strong>
            <span>{currMessage.landmark || "--"}</span>
          </p>
        </div>

        <div className="second">
          <p className="title">
            <span>Date Created:</span>
            <strong>
              {new Date(currMessage?.date_created).toLocaleString() || "--"}
            </strong>
          </p>
        </div>

        {/* <div className="third">
          <p>Pictures</p>

          {currMessage.image_url1 || currMessage.image_url2 ? (
            <ImgWrapper>
              {currMessage.image_url1 && (
                <div className="box">
                  <img src={currMessage.image_url1} alt={currMessage.issue} />
                </div>
              )}

              {currMessage.image_url2 && (
                <div className="box">
                  <img src={currMessage.image_url2} alt={currMessage.issue} />
                </div>
              )}
            </ImgWrapper>
          ) : null}
        </div> */}

        <div className="third">
          <h2 className="text-underline">Conversation</h2>

          {currMessage.response_data.map((response) => (
            <div className="mb-2">
              <p className="mb-min">
                <strong>Question: </strong>
                {response.question}
              </p>

              <p>
                <strong>Answer: </strong>
                {isValidURL(response.answer) ? (
                  <div className="box">
                    <img src={response.answer} alt={currMessage.issue} />
                  </div>
                ) : (
                  response.answer
                )}
              </p>
            </div>
          ))}
        </div>

        <StyledForm layout="vertical" className="form" form={form}>
          <Form.Item
            label="Agent Note"
            name="agent_note"
            rules={[
              {
                message: "agent note is required!",
                required: true,
              },
            ]}
          >
            <Input.TextArea rows={4} placeholder="Enter Agent Note" />
          </Form.Item>

          {/* <Form.Item
            label="L.G.A"
            name="local_gov"
            rules={[
              {
                message: "L.G.A is required!",
                required: true,
              },
            ]}
          >
            <Select size="large">
              {lgas.map((lga) => (
                <Option key={lga} value={lga}>
                  {lga}
                </Option>
              ))}
            </Select>
          </Form.Item> */}

          {/* <p>Select Escalators</p>

          <Flex>
            {agencies.map((d) => (
              <Checkbox
                key={d.id}
                checked={selectedEscalators.includes(d.id)}
                onChange={(e) => handleSelectEscalator(e, d.id)}
                disabled={d?.acronym === "LASEMA"}
              >
                {d.acronym}
              </Checkbox>
            ))}
          </Flex> */}

          {/* <div style={{ marginTop: "20px" }}>
            <p>Mark as Emergency</p>
            <Checkbox
              value={isEmergency}
              onChange={(e) => handleEmergency(e.target.checked)}
            >
              Emergency
            </Checkbox>
          </div> */}

          <FormItem
            label="Emergency Code"
            name="emergency_code"
            rules={[
              {
                message: "select an emergency code",
                required: true,
              },
            ]}
          >
            <Select
              mode="multiple"
              loading={isEmergencyCodesLoading}
              size="large"
              placeholder="Select Emergency Code"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {emergency_codes?.data?.map((d) => (
                <Option key={d.id} value={d.id}>
                  {d.code}
                </Option>
              ))}
            </Select>
          </FormItem>

          <FormItem label="Emergency Category" name="category" required>
            <Select size="large" placeholder="Select Category">
              {["emergency", "non_emergency", "hoax"].map((issue) => (
                <Option className="capitalize" key={issue} value={issue}>
                  {issue.split("_").join("-")}
                </Option>
              ))}
            </Select>
          </FormItem>
        </StyledForm>
      </div>

      <footer>
        <Button
          loading={loading}
          onClick={handleMessageEscalation}
          className="escalate-btn mr-3"
          type="primary"
        >
          Escalate
        </Button>

        <Button
          // loading={loading}
          onClick={() => toggleArchiveMessageModal()}
          className="archive-btn"
          type="primary"
          danger
        >
          Archive
        </Button>
      </footer>

      {isArchiveMessageModalOpen ? (
        <ArchiveMessageModal
          isOpen={isArchiveMessageModalOpen}
          closeModal={toggleArchiveMessageModal}
          closeParentModal={closeModal}
          currMessage={currMessage}
          refetchPendingMessages={refetchPendingMessages}
        />
      ) : null}
    </StyledModal>
  );
};

export default MessageDetailModal;
