import requests from "./httpService";

const IssueServices = {
  getIssues() {
    return requests.get(`/issues/`);
  },
  createIssue(body) {
    return requests.post("/issues/", body);
  },
  getSingleIssue(id) {
    return requests.get(`/issues/${id}`);
  },
  updateIssue(id, body) {
    return requests.put(`/issues/${id}/`, body);
  },
  deleteIssue(id) {
    return requests.delete(`/issues/${id}/`);
  },

  getEmergencyCodes() {
    return requests.get(`/emergency_codes/`);
  },

  addEmergencyCodes() {
    return requests.post(`/emergency_codes/`);
  },

  addNewQuestion(issueId, body) {
    return requests.post(`/issues/${issueId}/new_question/`, body);
  },
  updateQuestion(id, body) {
    return requests.put(`/questions/${id}/`, body);
  },
  deleteQuestion(id) {
    return requests.delete(`/questions/${id}/`);
  },
};

export default IssueServices;
