import styled from "styled-components";

const AuthSection = styled.section`
  display: flex;

  .bg-wrapper {
    min-height: 100vh;
    width: 50%;
    background: url("/asset/img/bg-login-img.png");
    background-size: cover;
    background-position: center;
  }
`;

const FormWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .max-width-wrapper {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    padding: 5% 1.5rem 0;

    .content {
      .logo {
        height: 75px;
        width: 150px;
        margin-bottom: 3rem;
        text-align: center;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        font-size: 32px;
      }

      .btn-submit-wrapper {
        margin-top: 3rem;
        text-align: center;

        button {
          width: 250px;
        }
      }
    }
  }
`;

export { AuthSection, FormWrapper };
