import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Tag,
  message,
  Space,
  DatePicker,
  Pagination,
} from "antd";
import { Link, useRouteMatch } from "react-router-dom";
import CardWrapper from "components/cardWrapper";
import SearchBar from "components/searchbar";
import { TableWrapper } from "./reportStyles";
import { OptionsMenu } from "./reportStyles";
import MessageServices from "config/services/MessageServices";
import useToggle from "hooks/useToggle";
import ViewReportModal from "./reportModal";
import { getEmergencyName } from "utils/helpers";
import requests from "config/services/httpService";

const months = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

const Reports = () => {
  const [current, setCurrent] = useState(1);
  const [currReport, setCurrReport] = useState();
  const [dateString, setDateString] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);

  let { path } = useRouteMatch();

  const [isReportModalOpen, toggleReportModal] = useToggle();

  useEffect(() => {
    const fetchNextData = async () => {
      try {
        setLoading(true);
        const data = await MessageServices.getAllMessages(current, pageSize);

        setTotal(data?.count);
        setResults(data?.results);
        setFilteredMessages(
          data?.results?.map((message, idx) => ({
            ...message,
            rank: idx + 1,
            reporter_name: message.name,
            address: message.address,
            incidence: message.issue,
            status: message.status,
            date: new Date(message.date_created).toLocaleDateString(),
          }))
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchNextData();
  }, [current, pageSize]);

  const columns = [
    {
      title: "S/N",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "Name of Reporter",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Incidence",
      dataIndex: "issue",
      key: "issue",
      render: (record) => <span className="capitalize">{record}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "center",

      render: (record, key) => {
        return (
          <Tag
            className="capitalize"
            color={
              record.toLowerCase() === "escalated"
                ? "red"
                : record.toLowerCase() === "pending"
                ? "yellow"
                : record.toLowerCase() === "completed"
                ? "green"
                : "blue"
            }
          >
            {record}
          </Tag>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Action",
      key: "action",
      align: "center",

      render: (record, key) => {
        return (
          <Button
            className="ant-btn-link"
            onClick={() => {
              setCurrReport(record);
              toggleReportModal();
            }}
            type="link"
          >
            view
          </Button>
        );
      },
    },
  ];

  const handleSearch = (value) => {
    const originalData = [
      ...results?.map((msg, idx) => ({
        ...msg,
        rank: idx + 1,
        name: msg?.name,
        address: msg?.address,
        category: getEmergencyName(msg?.category),
        status: msg?.status,
        date: new Date(msg?.date_created).toLocaleString(),
      })),
    ];

    if (!value) setFilteredMessages(originalData);

    const result = originalData.filter((d) => {
      return Object.keys(d).some((key) =>
        String(d[key]).toLowerCase().includes(value.toLowerCase())
      );
    });

    if (result.length === 0) {
      message.warning("No Data Found!", 5);
    }

    setFilteredMessages(result);
  };

  const onChange = (pageNumber) => {
    setCurrent(pageNumber);
  };

  // const month = months[new Date().getMonth()];
  // const year = new Date().getFullYear();

  return (
    <section>
      <CardWrapper
        title={`All Reports (${filteredMessages.length})`}
        extra={
          <Space>
            <DatePicker
              picker="month"
              // value={Date.now()}
              onChange={(value, dateString) => setDateString(dateString)}
            />

            <Button type="primary">
              <Link
                to={
                  dateString
                    ? `${path}/monthly?date=${dateString}`
                    : `${path}/monthly`
                }
              >
                Get Report
              </Link>
            </Button>
          </Space>
        }
      >
        <TableWrapper>
          <OptionsMenu>
            {/* <Space>
              <Button type="primary">Copy</Button>
              <Button type="primary">CSV</Button>
              <Button type="primary">Excel</Button>
              <Button type="primary">PDF</Button>
              <Button type="primary">Print</Button>
            </Space> */}

            <SearchBar handleSearch={handleSearch} />
          </OptionsMenu>

          <Table
            loading={loading}
            className="table-responsive"
            columns={columns}
            dataSource={filteredMessages}
            pagination={false}
          />
          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {loading ? null : (
              <Pagination
                current={current}
                pageSize={pageSize}
                total={total}
                onChange={onChange}
              />
            )}
          </div>
        </TableWrapper>
      </CardWrapper>

      {isReportModalOpen ? (
        <ViewReportModal
          visible={isReportModalOpen}
          closeModal={toggleReportModal}
          currReport={currReport}
        />
      ) : null}
    </section>
  );
};

export default Reports;
