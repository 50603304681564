import React from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { Button, Layout, Menu } from "antd";
import {
  LogoutOutlined,
  AppstoreOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import NavItems from "./navItems";
import {
  ContentLayout,
  HeaderStyled,
  LayoutStyled,
  MenuStyled,
} from "./layoutStyles";
import { useAuthContext } from "context/AuthContext";
import { ReactComponent as BurgerMenu } from "asset/svg/burgermenu.svg";
import { ReactComponent as AgentIcon } from "asset/svg/agents.svg";
import { ReactComponent as Logo } from "asset/svg/crm_logo.svg";
import { ReactComponent as EscalatorIcon } from "asset/svg/escalators.svg";
import { ReactComponent as FirstRespondersIcon } from "asset/svg/first-responders.svg";
import { ReactComponent as ReportIcon } from "asset/svg/reports.svg";

const { Content, Sider } = Layout;

const AdminLayout = ({ children }) => {
  const location = useLocation();

  const { url } = useRouteMatch();

  const sideMenu = [
    {
      title: "Dashboard",
      key: url + "/d",
      icon: <AppstoreOutlined />,
    },
    {
      title: "Incidents",
      key: url + "/d/incidents",
      icon: <NotificationOutlined />,
    },
    {
      title: "Reports",
      key: url + "/d/reports",
      icon: <ReportIcon />,
    },
    {
      title: "Agents",
      key: url + "/d/agents",
      icon: <AgentIcon />,
    },
    {
      title: "Escalators",
      key: url + "/d/escalators",
      icon: <EscalatorIcon />,
    },
    {
      title: "Agencies",
      key: url + "/d/agencies",
      icon: <AgentIcon />,
    },
    {
      title: "First Responders",
      key: url + "/d/first-responders",
      icon: <FirstRespondersIcon />,
    },
    // {
    //   title: "Profile",
    //   key: url + "/d/profile",
    //   icon: <UserOutlined />,
    // },
  ];

  const { dispatch } = useAuthContext();

  const handleLogOut = () => {
    dispatch({ type: "USER_LOGOUT" });
    localStorage.removeItem("adminInfo");
  };

  return (
    <LayoutStyled>
      <HeaderStyled className="header">
        <div className="wrapper">
          <div className="logo-wrapper">
            <button className="burgermenu-btn">
              <BurgerMenu className="burgermenu-icon" />
            </button>
            {/* <Logo /> */}

            <div className="logo-wrapper">
              {/* <img src={LagosLogo} alt="Logo" /> */}

              <Logo />
            </div>
          </div>

          <NavItems />
        </div>
      </HeaderStyled>

      <Layout>
        <Sider width={300} className="site-layout-background">
          <MenuStyled
            mode="inline"
            defaultSelectedKeys={["/d"]}
            selectedKeys={[location.pathname]}
          >
            {sideMenu.map((menu) => (
              <Menu.Item key={menu.key} icon={menu.icon}>
                <Link to={menu.key}>{menu.title}</Link>
              </Menu.Item>
            ))}
          </MenuStyled>

          <div className="btn-log-out">
            <Button onClick={handleLogOut} icon={<LogoutOutlined />}>
              Log Out
            </Button>
          </div>
        </Sider>

        <ContentLayout>
          <Content className="site-layout-background">{children}</Content>
        </ContentLayout>
      </Layout>
    </LayoutStyled>
  );
};

export default AdminLayout;
