import { message as MessageInfo } from "antd";

const handleErrors = (errorResponse) => {
  let errorMsg;

  const message =
    typeof errorResponse.data.error === "string"
      ? errorResponse.data.error
      : errorResponse.data.errors &&
        errorResponse.data.errors.length &&
        errorResponse.data.errors[0]
      ? errorResponse.data.errors.toString()
      : typeof errorResponse.data.error === "object"
      ? Object.entries(errorResponse.data.error)[0][1]
      : errorResponse.data.message;

  const detail =
    errorResponse && errorResponse.data.detail
      ? errorResponse.data.detail
      : errorResponse.detail;

  const statusText = errorResponse && errorResponse.statusText;

  if (message) {
    errorMsg = message;
  } else if (detail) {
    errorMsg = detail;
  } else {
    errorMsg = statusText;
  }

  MessageInfo.error(errorMsg);
};

export default handleErrors;
