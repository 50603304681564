import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import InputStyled from "components/inputStyled";

const FormItem = Form.Item;
const { Option } = Select;

const lgas = [
  "Agege",
  "Ajeromi-Ifelodun",
  "Alimosho",
  "Amuwo-Odofin",
  "Badagry",
  "Apapa",
  "Epe",
  "Eti Osa",
  "Ibeju-Lekki",
  "Ifako-Ijaiye",
  "Ikeja",
  "Ikorodu",
  "Kosofe",
  "Lagos Island",
  "Mushin",
  "Lagos Mainland",
  "Ojo",
  "Oshodi-Isolo",
  "Shomolu",
  "Surulere Lagos State",
];

const AddCaseModal = ({ visible, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      //   const values = await form.validateFields();

      setIsLoading(true);

      // const res = await api.addSector(values);

      // if (res.status === 201) {
      //   Modal.success({
      //     title: "Added Sector Successfully",
      //     onOk: async () => {
      //       await refetchSectors();
      //       closeModal();
      //     },
      //   });
      // }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(error);
    }
  };

  return (
    <Modal
      width={600}
      title={<strong>Add New Case</strong>}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button key="create" type="primary">
          Ok
        </Button>,
      ]}
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col lg={12}>
            <FormItem
              label="First Name"
              name="firstname"
              rules={[
                {
                  message: "Please input your First Name!",
                  required: true,
                },
              ]}
            >
              <InputStyled placeholder="First Name" />
            </FormItem>
          </Col>

          <Col lg={12}>
            <FormItem
              label="Last Name"
              name="lastName"
              rules={[
                {
                  message: "Please input your Last Name!",
                  required: true,
                },
              ]}
            >
              <InputStyled placeholder="Last Name" />
            </FormItem>
          </Col>

          <Col lg={12}>
            <FormItem
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not a valid E-mail!",
                },
                {
                  message: "Please input your Email!",
                  required: true,
                },
              ]}
            >
              <InputStyled placeholder="Email Address" />
            </FormItem>
          </Col>

          <Col lg={12}>
            <FormItem
              label="Phone Number"
              name="phone"
              rules={[
                {
                  message: "Please input your phone number!",
                  required: true,
                },
              ]}
            >
              <InputStyled placeholder="Phone Number" />
            </FormItem>
          </Col>
        </Row>

        <FormItem
          label="Address"
          name="address"
          rules={[
            {
              message: "Please input your address!",
              required: true,
            },
          ]}
        >
          <InputStyled placeholder="Address" />
        </FormItem>

        <Row gutter={16}>
          <Col lg={12}>
            <FormItem
              label="Local Government Area"
              name="lga"
              rules={[
                {
                  message: "Please select your lga!",
                  required: true,
                },
              ]}
            >
              <Select size="large">
                <Option value="ikorodu">Ikorodu</Option>
                <Option value="ikeja">Ikeja</Option>
                <Option value="alimosho">Alimosho</Option>
              </Select>
            </FormItem>
          </Col>

          <Col lg={12}>
            <FormItem label="Gender" name="gender">
              <Select size="large">
                {lgas.map((lga) => (
                  <Option key={lga} value={lga}>
                    {lga}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>

        <FormItem
          label="Case Description"
          name="case_description"
          rules={[
            {
              message: "Please input your case description!",
              required: true,
            },
          ]}
        >
          <Input.TextArea
            rows={4}
            placeholder="Enter description of reported case"
          />
        </FormItem>

        <FormItem
          label="Add Escalators"
          name="escalators"
          rules={[
            {
              message: "Please add escalators!",
              required: true,
            },
          ]}
        >
          <Select size="large" mode="multiple">
            <Option value="LASEMA">LASEMA</Option>
            <Option value="NPF">NPF</Option>
            <Option value="LASEMBUS">LASEMBUS</Option>
          </Select>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default AddCaseModal;
