import React, { useState } from "react";
import { Modal, Form, Button, Select } from "antd";

import InputStyled from "components/inputStyled";
import AdminServices from "config/services/AdminServices";
import { lgas } from "utils/helpers";
import { useUserContext } from "context/user";

const FormItem = Form.Item;
const { Option } = Select;

const EditAgentModal = ({ currAgent, visible, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { refetchAgentsData } = useUserContext();

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      const payload = { ...currAgent, ...values };

      setIsLoading(true);

      const res = await AdminServices.editUser(currAgent.id, payload);

      if (res.message === "success") {
        Modal.success({
          title: "Agent edited successfully",
          onOk: () => closeModal(),
        });
      }
      await refetchAgentsData();

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(error);
    }
  };

  return (
    <Modal
      title={<strong>Edit Agent</strong>}
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          loading={isLoading}
          key="create"
          type="primary"
          onClick={() => handleSubmit()}
        >
          Save
        </Button>,
      ]}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...currAgent,
        }}
      >
        <FormItem
          label="First Name"
          name="first_name"
          rules={[
            {
              message: "Please input your First Name!",
              required: true,
            },
          ]}
        >
          <InputStyled placeholder="First Name" />
        </FormItem>

        <FormItem
          label="Last Name"
          name="last_name"
          rules={[
            {
              message: "Please input your Last Name!",
              required: true,
            },
          ]}
        >
          <InputStyled placeholder="Last Name" />
        </FormItem>

        <FormItem
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not a valid E-mail!",
            },
            // {
            //   message: "Please input your Email!",
            //   required: true,
            // },
          ]}
        >
          <InputStyled placeholder="Email Address" />
        </FormItem>

        <FormItem
          label="Phone Number"
          name="phone"
          rules={[
            {
              message: "Please input your phone number!",
              required: true,
            },
          ]}
        >
          <InputStyled placeholder="Phone Number" />
        </FormItem>

        <FormItem
          label="Address"
          name="address"
          // rules={[
          //   {
          //     message: "Please input your address!",
          //     required: true,
          //   },
          // ]}
        >
          <InputStyled placeholder="Address" />
        </FormItem>

        <FormItem
          label="Local Government Area"
          name="local_gov"
          rules={[
            {
              message: "Please select your lga!",
              required: true,
            },
          ]}
        >
          <Select
            size="large"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {lgas.map((lga) => (
              <Option key={lga} value={lga}>
                {lga}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem label="Gender" name="gender">
          <Select size="large">
            <Option value="male">Male</Option>
            <Option value="female">Female</Option>
          </Select>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default EditAgentModal;
