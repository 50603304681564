import React from "react";
import { Route, Redirect, Switch, useRouteMatch } from "react-router-dom";
import Login from "../login";
import Agents from "./agents";
import Escalators from "./escalators";
import FirstResponders from "./firstResponders";
import Reports from "./reports";
import Agencies from "./agencies";
import Profile from "../agents/profile";
import CreateAccount from "../createAccount";
import Dashboard from "./dashboard";
import AdminLayout from "components/layout/adminLayout";
import Incidents from "./incidents";
import IncidentDetails from "./incidentDetails";
import UserProvider from "context/user";
import EscalatorProvider from "context/escalators";
import IssuesProvider from "context/issues";
import ChangePassword from "views/changePassword";
import ResetPassword from "views/resetPassword";
import MonthlyReports from "./monthlyReports";

const Admin = () => {
  let { path } = useRouteMatch();

  return (
    <UserProvider>
      <EscalatorProvider>
        <IssuesProvider>
          {/* <MessageProvider> */}
          <AdminLayout>
            <Switch>
              <Route exact path={path} component={Login} />
              <Route path={`${path}/login`} component={Login} />
              <Route
                path={path + "/create-account"}
                component={CreateAccount}
              />

              <Route exact path={path + "/d"} component={Dashboard} />
              <Route path={path + "/d/agents"} component={Agents} />
              <Route path={path + "/d/agencies"} component={Agencies} />
              <Route exact path={path + "/d/incidents"} component={Incidents} />
              <Route
                path={path + "/d/incidents/:id"}
                component={IncidentDetails}
              />
              {/* <Route path={path + "/d/incidences"} component={Incidences} /> */}
              <Route exact path={path + "/d/reports"} component={Reports} />
              <Route
                path={path + "/d/reports/monthly"}
                component={MonthlyReports}
              />
              <Route path={path + "/d/escalators"} component={Escalators} />
              <Route
                path={path + "/d/first-responders"}
                component={FirstResponders}
              />
              <Route
                path={path + "/d/change-password"}
                component={ChangePassword}
              />
              <Route
                path={path + "/d/reset-password"}
                component={ResetPassword}
              />
              <Route path={path + "/d/profile"} component={Profile} />
              <Redirect path="/*" to={{ pathname: path + "/d" }} />
            </Switch>
          </AdminLayout>
          {/* </MessageProvider> */}
        </IssuesProvider>
      </EscalatorProvider>
    </UserProvider>
  );
};

export default Admin;
